// import React from 'react';
import '../chatBotCustomStyles.css'

// export default function Avatar() {

//     return (
//         <div className='ADE' >ADE</div>
//     )
// }
import React from "react";
import { FaRobot } from "react-icons/fa"; // Importing bot icon

export default function Avatar() {
  return (
    <div
      style={{
        backgroundColor: "   #75a874", // Background color (matches your theme)
        borderRadius: "50%", // Circular shape
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "40px", // Avatar size
        height: "40px", // Avatar size
        color: "white", // Icon color
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow for floating effect
        fontSize: "24px", // Icon size
        transition: "background-color 0.3s ease", // Smooth transition for hover
      }}
    >
      <FaRobot style={{ color: "white", fontSize: "24px" }} />
    </div>
  );
}
