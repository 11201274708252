

import React, { useState } from "react";
import { ApiPath } from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    const [chatId, setChatId] = useState(null);

    // Generates an 8-digit random chatId as a string.
    const generateChatId = () =>
      Math.floor(10000000 + Math.random() * 90000000).toString();
  
    /**
     * updateState updates the UI messages and records the conversation by pushing a single entry
     * containing the user message and the bot reply.
     *
     * @param {Object} botMessage - Object generated by createChatBotMessage for bot reply.
     * @param {string|null} userMessage - The user's text (if available).
     */
    const updateState = (botMessage, userMessage = null) => {
      // Update the UI state for messages.
      setState((prev) => ({
        ...prev,
        messages: userMessage
          ? [
              ...prev.messages,
              createChatBotMessage(userMessage, { type: "user" }),
              botMessage
            ]
          : [...prev.messages, botMessage]
      }));
  
      // Extract text from the message object.
      const extractText = (msg) =>
        msg.message || msg.text || "[Text not available]";
  
      // Build a conversation entry for the backend.
      let newEntry = {};
      if (userMessage !== null) {
        newEntry = {
          usermsg: userMessage,
          chatbotreply: extractText(botMessage),
          timestamp: new Date()
        };
      } else {
        // For single messages, record user text if it's a user message.
        if (botMessage.type && botMessage.type === "user") {
          newEntry = {
            usermsg: extractText(botMessage),
            chatbotreply: "",
            timestamp: new Date()
          };
        } else {
          newEntry = {
            usermsg: "",
            chatbotreply: extractText(botMessage),
            timestamp: new Date()
          };
        }
      }
  
      // If chatId is not set yet, generate one and start the conversation.
      let currentChatId = chatId;
      if (!currentChatId) {
        currentChatId = generateChatId();
        setChatId(currentChatId);
        // POST request to start the chat session.
        axios
          .post("https://pagoanalytics.azurewebsites.net/chatbothistory/api/chat/start", {
            chatId: currentChatId,
            email: "",
            name: "",
            phone: "",
            otherInfo: "",
            chatHistory: []
          })
          .then((response) => {
            console.log("Chat session started with id:", currentChatId);
          })
          .catch((error) => {
            console.error("Error starting chat conversation:", error);
          });
      }
  
      // Push the new conversation entry to the backend.
      axios
        .put(
          `https://pagoanalytics.azurewebsites.net/chatbothistory/api/chat/update/${currentChatId}`,
          newEntry
        )
        .then((res) => {
          console.log("Chat updated:", res.data);
        })
        .catch((err) => console.error("Error updating chat:", err));
    };

    const updateBotOnlyState = (botMessage, userMessage = null) => {
    // Update the UI state for messages.
    setState((prev) => ({
      ...prev,
      messages: userMessage
        ? [
            ...prev.messages,
            // createChatBotMessage(userMessage, { type: "user" }),
            botMessage
          ]
        : [...prev.messages, botMessage]
    }));

    // Extract text from the message object.
    const extractText = (msg) =>
      msg.message || msg.text || "[Text not available]";

    // Build a conversation entry for the backend.
    let newEntry = {};
    if (userMessage !== null) {
      newEntry = {
        usermsg: userMessage,
        chatbotreply: extractText(botMessage),
        timestamp: new Date()
      };
    } else {
      // For single messages, record user text if it's a user message.
      if (botMessage.type && botMessage.type === "user") {
        newEntry = {
          usermsg: extractText(botMessage),
          chatbotreply: "",
          timestamp: new Date()
        };
      } else {
        newEntry = {
          usermsg: "",
          chatbotreply: extractText(botMessage),
          timestamp: new Date()
        };
      }
    }

    // If chatId is not set yet, generate one and start the conversation.
    let currentChatId = chatId;
    if (!currentChatId) {
      currentChatId = generateChatId();
      setChatId(currentChatId);
      // POST request to start the chat session.
      axios
        .post("https://pagoanalytics.azurewebsites.net/chatbothistory/api/chat/start", {
          chatId: currentChatId,
          email: "",
          name: "",
          phone: "",
          otherInfo: "",
          chatHistory: []
        })
        .then((response) => {
          console.log("Chat session started with id:", currentChatId);
        })
        .catch((error) => {
          console.error("Error starting chat conversation:", error);
        });
    }

    // Push the new conversation entry to the backend.
    axios
      .put(
        `https://pagoanalytics.azurewebsites.net/chatbothistory/api/chat/update/${currentChatId}`,
        newEntry
      )
      .then((res) => {
        console.log("Chat updated:", res.data);
      })
      .catch((err) => console.error("Error updating chat:", err));
    };
    
  
        // Example: Generic response for direct calls
  const handleGenericResponse = (botReply, userMessage) => {
    const botMsg = createChatBotMessage(botReply);
    updateBotOnlyState(botMsg, userMessage);
  };


    /**
     * createUserMessage is called when a user types a message directly from the input.
     * It records the user's message (with no bot reply yet). Later, when the bot responds,
     * you can update that entry as needed. For simplicity, we record each message as its own exchange.
     */
    const createUserMessage = (messageText) => {
      const userMsg = createChatBotMessage(messageText, { type: "user" });
      updateState(userMsg);
    };

 
  // ------------------ Chatbot Action Functions ------------------

  const handleShippingPolicy = () => {
    const message = createChatBotMessage(
      "Any Order Value below Rs.249, the shipping charge will be - Rs.40/-Any Order Value between Rs.250 - 499, the shipping will be - Rs.60/-Any Order Value between Rs.500 - 999, the shipping will be - Rs.80/-All orders above Rs.1000/- will be free.-Orders are processed within 2-3 days of receipt. Delivery timeline is 2-10 days.-If delayed due to unforeseen circumstances, please raise a ticket to sales@thejaavapot.com.",
      { widget: "confirmationWidget" }
    );
    updateState(message, "What is your shipping policy?");
  };

  const handleTrackOrder = () => {
    const message = createChatBotMessage(
      "Please provide your order number, and I will fetch the tracking details for you.",
      { widget: "trackOrderEmailWidget" }
    );
    updateState(message, "Track Your Order");
  };

  const handleSupportHelp = () => {
    const message = createChatBotMessage(
      "The Javapot Support team is here to assist you. How can we help today?",
      { widget: "supportOptionsWidget" }
    );
    updateState(message, "The Javapot Support Help");
  };

  const handleThankYou = () => {
    const message = createChatBotMessage("You're welcome! Have a great day! 😊");
    updateState(message, "Yes, Thank You");
  };

  const handleMoreHelp = () => {
    const message = createChatBotMessage("Sure! How else can I assist you?", {
      widget: "supportOptionsWidget"
    });
    updateState(message, "No, I need More Help");
  };

  const handleWhatsAppSupport = () => {
    const message = createChatBotMessage(
      "You can chat with our support team on WhatsApp. Click below to continue.",
      { widget: "whatsappWidget" }
    );
    updateState(message, "I need help via WhatsApp.");
  };

  const handleCallSupport = () => {
    const message = createChatBotMessage(
      "Would you like to call us now or request a callback?",
      { widget: "callSupportWidget" }
    );
    updateState(message, "I need call support.");
  };

  const handleFetchOrdersByEmail = async (email) => {
    // console.log("🔍 Fetching orders for:", email);
    updateState(createChatBotMessage("Fetching your orders... 🔄"));
    try {
      const response = await fetch(`${ApiPath}/jaavapotorders/api/orders/${email}`);
      const data = await response.json();
      console.log("✅ API Response:", data);
      if (!Array.isArray(data)) {
        updateState(createChatBotMessage("⚠️ Unexpected response from server."));
        return;
      }
      const activeOrders = data.filter(
        (order) =>
          order.status !== "Delivered" &&
          order.status !== "Rejected" &&
          order.status !== "Canceled-By-User" &&
          order.status !== "Order-Cancelled-By-TJP"
      );
      if (activeOrders.length > 0) {
        const orderMessage = createChatBotMessage("Here are your active orders:", {
          widget: "orderSelectionWidget",
          payload: { orders: activeOrders }
        });
        updateState(orderMessage);
      } else {
        updateState(createChatBotMessage("✅ You have no active orders."));
      }
    } catch (error) {
      console.error("❌ API Fetch Error:", error);
      updateState(createChatBotMessage("⚠️ Error fetching orders. Please try again."));
    }
  };

  const handleOrderDetails = (order) => {
    console.log("📦 Selected Order:", order);
    const orderMessage = createChatBotMessage("Here is your order status:", {
      widget: "orderStatusWidget",
      payload: { order }
    });
    updateState(orderMessage);
    setTimeout(() => {
      const confirmationMessage = createChatBotMessage("Would you like to proceed with any other actions?", {
        widget: "confirmationWidget"
      });
      updateState(confirmationMessage);
    }, 10000);
  };

  const handleStayUpdated = () => {
    const message = createChatBotMessage("Please enter your details to stay updated:", {
      widget: "stayUpdatedWidget"
    });
    updateState(message, "Stay Updated with Deals");
  };

  const handlePartnerCollab = () => {
    const message = createChatBotMessage("Enter your WhatsApp number to collaborate:", {
      widget: "partnerCollabWidget"
    });
    updateState(message, "Be our Partner / Collaboration");
  };

  const handleStayUpdatedSubmit = async (name, contact) => {
    if (!name || !contact) {
      updateState(createChatBotMessage("⚠️ Please enter both Name and Contact."));
      return;
    }
    updateState(createChatBotMessage("⏳ Submitting your details..."));
    try {
      const thankYouMessage = createChatBotMessage(
        `✅ Thank you, ${name}! You'll receive updates soon.`
      );
      updateState(thankYouMessage, `My name is ${name}, and my contact is ${contact}.`);
    } catch (error) {
      console.error("Error submitting contact:", error);
      updateState(createChatBotMessage("❌ Error submitting. Please try again."));
    }
  };

  const handlePartnerCollabSubmit = async (name, number) => {
    if (!name || !number) {
      updateState(createChatBotMessage("⚠️ Please enter both Name and Number."));
      return;
    }
    updateState(createChatBotMessage("⏳ Processing your request..."));
    try {
      const thankYouMessage = createChatBotMessage(
        `✅ Thank you, ${name}! We will reach out to you shortly.`
      );
      updateState(thankYouMessage, `My name is ${name}, and my number is ${number}.`);
    } catch (error) {
      console.error("Error submitting partner request:", error);
      updateState(createChatBotMessage("❌ Error submitting. Please try again."));
    }
  };

  const handleHii = () => {
    const message = createChatBotMessage("Hi there How can I help You today", {
      widget: "partnerCollabWidget"
    });
    updateState(message, "Be our Partner / Collaboration");
  };

  // New product and nutrition actions
  const handleChildrensProduct = () => {
    updateState(
      createChatBotMessage("Here are our best products for children:", { widget: "childrensProductWidget" }),
      "Show me children’s products"
    );
  };

  const handleSeniorCombos = () => {
    updateState(
      createChatBotMessage("Here are our specially curated combos for seniors:", { widget: "seniorCombosWidget" }),
      "Show me senior combos"
    );
  };

  const handleWomenCombos = () => {
    updateState(
      createChatBotMessage("Here are our exclusive combos for women’s health:", { widget: "womenCombosWidget" }),
      "Show me women’s combos"
    );
  };

  const handleSoupCombos = () => {
    updateState(
      createChatBotMessage("Here are our delicious and healthy soup combos:", { widget: "soupCombosWidget" }),
      "Show me soup combos"
    );
  };

  const handleMilletCombos = () => {
    updateState(
      createChatBotMessage("Check out our millet-based combo packs:", { widget: "milletCombosWidget" }),
      "Show me millet combos"
    );
  };

  const handleWholesomeBasket = () => {
    updateState(
      createChatBotMessage("Enjoy a complete nutrition-packed basket:", { widget: "wholesomeBasketWidget" }),
      "Show me the wholesome basket"
    );
  };

  const handleEssentialBoost = () => {
    updateState(
      createChatBotMessage("Boost your nutrition with these essential products:", { widget: "essentialBoostWidget" }),
      "Show me essential boost products"
    );
  };

  const handleCustomizeCombo = () => {
    updateState(
      createChatBotMessage("Create your own combo with your favorite items:", { widget: "customizeComboWidget" }),
      "I want to customize a combo"
    );
  };

  const handleHealthySoups = () => {
    updateState(
      createChatBotMessage("Explore our range of healthy soups:", { widget: "healthySoupsWidget" }),
      "Show me healthy soups"
    );
  };

  const handleHealthyPorridges = () => {
    updateState(
      createChatBotMessage("Check out our nutritious porridge options:", { widget: "healthyPorridgesWidget" }),
      "Show me healthy porridges"
    );
  };

  const handleImmunityBoosters = () => {
    updateState(
      createChatBotMessage("Boost your immunity with these products:", { widget: "immunityBoostersWidget" }),
      "Show me immunity boosters"
    );
  };

  const handleSuperfood = () => {
    updateState(
      createChatBotMessage("Discover our collection of superfoods:", { widget: "superfoodWidget" }),
      "Show me superfoods"
    );
  };

  const handleAllProducts = () => {
    updateState(
      createChatBotMessage("Browse all our available products:", { widget: "allProductsWidget" }),
      "Show me all products"
    );
  };

  const handleOrderNow = () => {
    const message = createChatBotMessage("Please choose one option:", { widget: "orderNowWidget" });
    updateState(message, "Order Now");
  };

  const handleReadyToCook = () => {
    const message = createChatBotMessage("Select a Ready To Cook product:", { widget: "readyToCookWidget" });
    updateState(message, "Ready To Cook");
  };

  const handleReadyToEat = () => {
    const message = createChatBotMessage("Select a Ready To Eat product:", { widget: "readyToEatWidget" });
    updateState(message, "Ready To Eat");
  };

  const handleAddMoreProducts = () => {
    const message = createChatBotMessage("Okay, continue adding products.", { widget: "orderNowWidget" });
    updateState(message);
  };

  const promptMoreProducts = () => {
    const message = createChatBotMessage("Would you like to add more?", { widget: "moreProductsPromptWidget" });
    updateState(message);
  };

  const handleConfirmOrder = () => {
    const message = createChatBotMessage("Please confirm your order details:", { widget: "confirmOrderWidget" });
    updateState(message);
  };

  const submitOrder = (address) => {
    const payload = { selectedAddress: address };
    const message = createChatBotMessage("Here is your order summary:", {
      widget: "chatOrderSummaryWidget",
      payload
    });
    updateState(message, "Order Confirmed");
  };

  const orderPlaced = () => {
    const successMessage = createChatBotMessage("Your order is placed!", { widget: "orderPlacedWidget" });
    updateState(successMessage);
  };

  // This function creates a user message from ActionProvider.
//   const createUserMessage = (messageText) => {
//     // When a user types a message directly, record it as a user entry.
//     const message = createChatBotMessage(messageText, { type: "user" });
//     updateState(message);
//   };

  // Function to generate a bot response when a product is added.
  const productAddedResponse = (productName) => {
    const botMessage = createChatBotMessage(`Added ${productName} to your cart.`);
    updateState(botMessage);
  };

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          actions: {
            handleShippingPolicy,
            handleTrackOrder,
            handleSupportHelp,
            handleThankYou,
            handleMoreHelp,
            handleWhatsAppSupport,
            handleCallSupport,
            handleFetchOrdersByEmail,
            handleOrderDetails,
            handleStayUpdated,
            handlePartnerCollab,
            handleStayUpdatedSubmit,
            handlePartnerCollabSubmit,
            handleHii,
            handleChildrensProduct,
            handleSeniorCombos,
            handleWomenCombos,
            handleSoupCombos,
            handleMilletCombos,
            handleWholesomeBasket,
            handleEssentialBoost,
            handleCustomizeCombo,
            handleHealthySoups,
            handleHealthyPorridges,
            handleImmunityBoosters,
            handleSuperfood,
            handleAllProducts,
            handleOrderNow,
            handleReadyToCook,
            handleReadyToEat,
            handleAddMoreProducts,
            promptMoreProducts,
            handleConfirmOrder,
            submitOrder,
            orderPlaced,
            createUserMessage,
            productAddedResponse,
            handleGenericResponse
          },
          setState,
          // createChatBotMessage,
        })
      )}
    </div>
  );
};

export default ActionProvider;
