import React from "react";
import '../ChatWidgets.css'

const OrderSelectionWidget = ({ payload, actions }) => {
    console.log("📩 Widget Props Received:", payload); // ✅ Log Entire Props

    const { orders } = payload; // ✅ Extract orders properly

    if (!orders || !Array.isArray(orders) || orders.length === 0) {
        console.log("❌ No orders found in payload!");
        return <p>🚫 No active orders available.</p>;
    }

    console.log("✅ Orders Found in Widget:", orders); // ✅ Log Orders Before Rendering

    return (
        <div className="widget-container">
            <p>Select an order to view details:</p>
            {orders.map((order) => {
                console.log("🛍️ Order Item Data:", order.order_data); // ✅ Log Each Order
                
                const item = order.order_data?.[0]?.[0];  // ✅ Get first item safely
                const orderInfo = order.order_data?.[2]; // ✅ Get order info safely

                if (!item || !orderInfo) return null; // Prevent rendering broken entries
                
                return (
                    <button 
                        key={order._id} 
                        className="widget-button"
                        onClick={() => actions.handleOrderDetails(order)}
                    >
                        📦 {item.name} - {orderInfo.orderId} <br />
                        🗓 Ordered on: {new Date(order.timestamp).toLocaleDateString()}
                    </button>
                );
            })}
        </div>
    );
};

export default OrderSelectionWidget;
