// import React, { useState } from "react";

// const StayUpdatedWidget = ({ actions }) => {
//     const [contact, setContact] = useState("");
//     const [name, setName] = useState("");
//     const [loading, setLoading] = useState(false);

//     const handleSubmit = () => {
//         setLoading(true);
//         actions.handleStayUpdatedSubmit(name, contact);
//         setLoading(false);
//     };

//     return (
//         <div>
//             <p>Enter your name and email or phone number to stay updated:</p>
//             <input
//                 type="text"
//                 placeholder="Your Name"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 disabled={loading}
//             />
//             <input
//                 type="text"
//                 placeholder="Email or Phone"
//                 value={contact}
//                 onChange={(e) => setContact(e.target.value)}
//                 disabled={loading}
//             />
//             <button onClick={handleSubmit} disabled={loading}>
//                 {loading ? "Submitting..." : "Submit"}
//             </button>
//         </div>
//     );
// };

// export default StayUpdatedWidget;

import React, { useState } from "react";
import '../ChatWidgets.css'

const StayUpdatedWidget = ({ actions }) => {
    const [contact, setContact] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        actions.handleStayUpdatedSubmit(name, contact);
        setLoading(false);
    };

    return (
        <div className="widget-container">
            <p>Enter your name and email or phone number to stay updated:</p>
            <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={loading}
                className="widget-input"
            />
            <input
                type="text"
                placeholder="Email or Phone"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                disabled={loading}
                className="widget-input"
            />
            <button onClick={handleSubmit} disabled={loading} className="widget-button option-button">
                {loading ? "Submitting..." : "Submit"}
            </button>
        </div>
    );
};

export default StayUpdatedWidget;