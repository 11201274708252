// SufficientInfo.js
import React from "react";

const SufficientInfo = ({ actions }) => {
  return (
    <div style={{ marginTop: "20px", textAlign: "center" }}>
      <p style={{ fontWeight: "bold", marginBottom: "10px",color:' #CCCCCC',lineHeight:"20px",fontSize:'16px' }}>
        Is this information sufficient?
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        <button
          onClick={actions.handleThankYou}
          style={{
            padding: "8px 16px",
            borderRadius: "8px",
            border: "none",
            background: "#fff",
            color: "#FD7E4B",
            cursor: "pointer",
            fontSize:'16px',
            fontWeight:"700"
          }}
        >
          Satisfied
        </button>
        <button
          onClick={actions.handleSupportHelp}
          style={{
            padding: "8px 16px",
            borderRadius: "8px",
            border: "none",
            background: "#fff",
            color: "#FD7E4B",
            cursor: "pointer",
            fontSize:'16px',
            fontWeight:"700"
          }}
        >
          Need Help
        </button>
      </div>
    </div>
  );
};

export default SufficientInfo;
