import React from "react";
import { FaUser } from "react-icons/fa"; // Import user icon

const UserAvatar = () => {
    return (
        <div style={styles.avatarContainer}>
            <FaUser style={styles.avatarIcon} />
        </div>
    );
};

const styles = {
    avatarContainer: {
        width: 40,
        height: 40,
        borderRadius: "50%",
        backgroundColor: " #75a874", // Customize this color
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF", // Customize the icon color
        fontSize: 20,
        border: "2px solid #75a874", // Border styling
    },
    avatarIcon: {
        fontSize: 22, // Adjust icon size
    },
};

export default UserAvatar;
