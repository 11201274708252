// import React from "react";
// import "../../ChatWidgets.css";

// const MoreProductsPromptWidget = ({ actions }) => {
//   return (
//     <div className="widget-container">
//       <p>Want to add more?</p>
//       <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
//         <button
//           onClick={() => actions.handleAddMoreProducts()}
//           className="widget-button option-button"
//         >
//           Yes
//         </button>
//         <button
//           onClick={() => actions.handleConfirmOrder()}
//           className="widget-button option-button"
//         >
//           No
//         </button>
//       </div>
//     </div>
//   );
// };

// export default MoreProductsPromptWidget;

import React from "react";
import "../../ChatWidgets.css";

const MoreProductsPromptWidget = ({ actions }) => {
  return (
    <div className="widget-container" style={{ maxWidth: "350px", margin: "0 auto", padding: "0 10px" }}>
      <p style={{ textAlign: "center", marginBottom: "12px" }}>Would you like to add more products?</p>
      <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
        <button
          onClick={() => actions.handleAddMoreProducts()}
          className="widget-button-more-products"
          style={{ flex: 1, marginRight: "5px", whiteSpace: "nowrap" ,color:'#fff'}}
        >
          Add More
        </button>
        <button
          onClick={() => actions.handleConfirmOrder()}
          className="widget-button-more-products"
          style={{ flex: 1, marginLeft: "5px", whiteSpace: "nowrap" ,color:'#fff'}}
        >
          Confirm Order
        </button>
      </div>
    </div>
  );
};

export default MoreProductsPromptWidget;
