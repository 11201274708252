// import React, { useState } from "react";
// import './partnerStyles.css';

// const PartnerCollabWidget = ({ actions }) => {
//     const [name, setName] = useState("");
//     const [number, setNumber] = useState("");
//     const [loading, setLoading] = useState(false);
//     const [showForm, setShowForm] = useState(false);

//     const handleSubmit = () => {
//         if (!name || !number) {
//             return;
//         }

//         setLoading(true);
//         actions.handlePartnerCollabSubmit(name, number);
//         setLoading(false);
//     };

//     return (
//         <div className="partner-widget-container">
//             <p>How would you like to connect with us?</p>
//             <button className="partner-widget-button call-button" onClick={actions.handleCallSupport}>
//                 📞 Call Us Now
//             </button>
//             <button className="partner-widget-button whatsapp-button" onClick={actions.handleWhatsAppSupport}>
//                 💬 WhatsApp Message
//             </button>
//             <button className="partner-widget-button details-button" onClick={() => setShowForm(true)}>
//                 📋 Share Your Details to Receive a Call
//             </button>

//             {showForm && (
//                 <div className="partner-widget-form">
//                     <h3>Share Your Details to Receive a Call</h3>
//                     <p>Please enter your details below:</p>
//                     <input
//                         className="partner-widget-input"
//                         type="text"
//                         placeholder="Your Name"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         disabled={loading}
//                     />
//                     <input
//                         className="partner-widget-input"
//                         type="text"
//                         placeholder="Your Phone Number"
//                         value={number}
//                         onChange={(e) => setNumber(e.target.value)}
//                         disabled={loading}
//                     />
//                     <button className="partner-widget-submit" onClick={handleSubmit} disabled={loading}>
//                         {loading ? "Submitting..." : "Submit"}
//                     </button>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default PartnerCollabWidget;
import React, { useState, useRef } from "react";
import './partnerStyles.css';

const PartnerCollabWidget = ({ actions }) => {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);

    // Ref to the form section
    const formRef = useRef(null);

    const handleShowForm = () => {
        setShowForm(true);
        setTimeout(() => {
            formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 200); // Delay to ensure form renders before scrolling
    };

    const handleSubmit = () => {
        if (!name || !number) return;

        setLoading(true);
        actions.handlePartnerCollabSubmit(name, number);
        setLoading(false);
    };

    return (
        <div className="partner-widget-container">
            <p>How would you like to connect with us?</p>
            <button className="partner-widget-button call-button" onClick={actions.handleCallSupport}>
                📞 Call Us Now
            </button>
            <button className="partner-widget-button whatsapp-button" onClick={actions.handleWhatsAppSupport}>
                💬 WhatsApp Message
            </button>
            <button className="partner-widget-button details-button" onClick={handleShowForm}>
                📋 Share Your Details to Receive a Call
            </button>

            {showForm && (
                <div ref={formRef} className="partner-widget-form">
                    <h3>Share Your Details to Receive a Call</h3>
                    <p>Please enter your details below:</p>
                    <input
                        className="partner-widget-input"
                        type="text"
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled={loading}
                    />
                    <input
                        className="partner-widget-input"
                        type="text"
                        placeholder="Your Phone Number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        disabled={loading}
                    />
                    <button className="partner-widget-submit" onClick={handleSubmit} disabled={loading}>
                        {loading ? "Submitting..." : "Submit"}
                    </button>
                </div>
            )}
        </div>
    );
};

export default PartnerCollabWidget;
