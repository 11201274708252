// import React from "react";
// import MarqueeSlider from "react-marquee-slider";
// import './marquee.css';
// // import img1 from '../../../../assets/home/partners/logo.png';
// import img1 from '../../../../assets/home/partners/partners01.png';
// import img2 from '../../../../assets/home/partners/partners02.png';
// import img3 from '../../../../assets/home/partners/partners03.png';
// import img4 from '../../../../assets/home/partners/partners04.png';
// import img5 from '../../../../assets/home/partners/partners05.png';
// import img6 from '../../../../assets/home/partners/partners06.png';
// import img7 from '../../../../assets/home/partners/partners07.png';
 
// export default function Marquee() {
//     const images = [img1, img2, img3, img4, img5, img6,img7 ];
 
//     return (
//         <div className="slider-container">
//             <MarqueeSlider
//                 velocity={30} // Speed of the scrolling
//                 minScale={1}  // Scale of images
//                 direction="rtl" // Direction of scrolling (left-to-right)
//                 resetAfterTries={100} // Auto-reset after 100 loops to avoid memory issues
//             >
//                 {images.map((img, index) => (
//                     <div className="slide" key={index}>
//                         <img src={img} height="56" width="250" alt={`Slide ${index + 1}`} />
//                     </div>
//                 ))}
//             </MarqueeSlider>
//         </div>
//     );
// }
 

import React from "react";
import MarqueeSlider from "react-marquee-slider";
import './marquee.css';
// import img1 from '../../../../assets/home/partners/logo.png';
import img1 from '../../../../assets/home/partners/partners01.png';
import img2 from '../../../../assets/home/partners/partners02.png';
import img3 from '../../../../assets/home/partners/partners03.png';
import img4 from '../../../../assets/home/partners/partners04.png';
import img5 from '../../../../assets/home/partners/partners05.png';
import img6 from '../../../../assets/home/partners/partners06.png';
import img7 from '../../../../assets/home/partners/partners07.png';
import { myColors } from "../../../../contants/Colors";

export default function Marquee() {
    const images = [img1, img2, img3, img4, img5, img6, img7];

    return (
        <div className="marquee-slider-container">
            <h2 className="marquee-slider-title" style={{color:myColors.primaryOrange}}>Our Partner</h2>
            <MarqueeSlider
                velocity={30} // Speed of the scrolling
                minScale={1}  // Scale of images
                direction="rtl" // Direction of scrolling (right-to-left)
                resetAfterTries={100} // Auto-reset after 100 loops to avoid memory issues
            >
                {images.map((img, index) => (
                    <div className="marquee-slide" key={index}>
                        <img className="marquee-image" src={img} height="70" width="250" alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </MarqueeSlider>
        </div>
    );
}
