import React, { useState, useEffect, useRef } from "react";
import { FaRobot, FaCheckCircle } from "react-icons/fa";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import SufficientInfo from "../../../../components/utilities/common/SufficientInfo";

// ─── ReusableWidget ──────────────────────────────────────────────

const ReusableWidget = ({ title, description, features, process, benefits, actions }) => {
  const [visible, setVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const widgetRef = useRef(null);
  const infoRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (visible && widgetRef.current) {
      widgetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const timer2 = setTimeout(() => setInfoVisible(true), 10000);
      return () => clearTimeout(timer2);
    }
  }, [visible]);

  useEffect(() => {
    if (infoVisible && infoRef.current) {
      infoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [infoVisible]);

  if (!visible) return null;

  return (
    <div
      ref={widgetRef}
      className="custom-software-widget"
      style={{
        maxWidth: "600px",
        margin: "20px auto",
        display: "flex",
        alignItems: "flex-start"
      }}
    >
      <div
        style={{
          marginRight: "12px",
          marginTop: "8px",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: "#FD7E4B",
          // backgroundColor: "#054570",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      > 
        <FaRobot size={23} color="#fff" />
      </div>
      <div
        style={{
          backgroundColor: "#FD7E4B",
          borderRadius: "12px",
          padding: "16px",
          color: "#fff",
          boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
          flex: 1,
          position: "relative"
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "-10px",
            top: "20px",
            width: 0,
            height: 0,
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderRight: "10px solid #FD7E4B"
          }}
        />
        <header style={{ marginBottom: "12px" }}>
          <h2 style={{ margin: 0, fontSize: "20px", fontWeight: "bold", color: "#fff" }}>
            {title}
          </h2>
        </header>
        <p style={{ margin: "12px 0" }}>{description}</p>
        <div style={{ marginTop: "16px" }}>
          <h3 style={{ fontSize: "18px", margin: "8px 0", color: "#fff" }}>Key Features</h3>
          <div style={{ paddingLeft: "20px", margin: "8px 0" }}>
            {features.map((feature, index) => (
              <p key={index}>{feature}</p>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "1px" }}>
          <h3 style={{ fontSize: "18px", margin: "8px 0", color: "#fff" }}>Our Process</h3>
          <div style={{ paddingLeft: "0px", margin: "8px 0" }}>
            {process.map((step, index) => (
              <p key={index}>
                <IoArrowForwardCircleSharp size={24} color="#fff" style={{ marginRight: "8px" }} />
                {step}
              </p>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "1px" }}>
          <h3 style={{ fontSize: "18px", margin: "8px 0", color: "#fff" }}>Benefits</h3>
          <div style={{ paddingLeft: "10px", margin: "8px 0" }}>
            {benefits.map((benefit, index) => (
              <p key={index}>
                <FaCheckCircle size={24} color="#fff" style={{ marginRight: "12px" }} />
                {benefit}
              </p>
            ))}
          </div>
        </div>
        {infoVisible && (
          <div ref={infoRef}>
            <SufficientInfo actions={actions} />
          </div>
        )}
      </div>
    </div>
  );
};

// ─── Widget Components ────────────────────────────────────────────

// 1. ChildrensProductWidget
export const ChildrensProductWidget = (props) => {
  const data = {
    title: "Products for Children",
    description:
      "Our range for children includes combos specially designed to support growth and immunity.",
    features: [
      "Super Kids Combo",
      "Power Up Kids Combo",
      "Vibrant Kids Combo",
      "Healthy Kids Combo"
    ],
    process: [
      "Simple ordering process",
      "Quick delivery",
      "Easy customization options"
    ],
    benefits: [
      "Supports healthy growth",
      "Boosts immunity",
      "Kid-friendly flavors"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 2. SeniorCombosWidget
export const SeniorCombosWidget = (props) => {
  const data = {
    title: "Senior Power Combos",
    description:
      "Our Senior Power Combos offer easy-to-digest, balanced meals that maintain vitality and support overall health.",
    features: [
      "Easy-to-digest meals",
      "Balanced nutrition",
      "Traditional recipes"
    ],
    process: [
      "Sourced from quality ingredients",
      "Expertly prepared",
      "Delivered fresh"
    ],
    benefits: [
      "Boosts vitality",
      "Supports bone and heart health",
      "Maintains energy levels"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 3. WomenCombosWidget
export const WomenCombosWidget = (props) => {
  const data = {
    title: "Women Combos",
    description:
      "Our Radiant Women and Glowing Women combos are crafted with nutrient-rich meals that support skin health, energy, and overall well-being.",
    features: [
      "Skin nourishing recipes",
      "Energy boosting ingredients",
      "Delicious and healthy"
    ],
    process: [
      "Carefully curated combinations",
      "Quality ingredients",
      "Balanced meals"
    ],
    benefits: [
      "Enhances radiance",
      "Promotes overall wellness",
      "Boosts energy levels"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 4. SoupCombosWidget
export const SoupCombosWidget = (props) => {
  const data = {
    title: "Soup Combos",
    description:
      "Our Soup-o-Week Combos feature a variety of healthy soups packed with vitamins and antioxidants.",
    features: [
      "Variety of soups",
      "Antioxidant rich",
      "Nutrient dense"
    ],
    process: [
      "Slow simmered for enhanced flavor",
      "Freshly prepared",
      "Perfect for every day"
    ],
    benefits: [
      "Boosts immunity",
      "Supports digestion",
      "Enhances overall health"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 5. MilletCombosWidget
export const MilletCombosWidget = (props) => {
  const data = {
    title: "Millet Combos",
    description:
      "Our Millet Pro Fitness Combos and millet-based meals provide the energy and nutrition you need.",
    features: [
      "High in fiber",
      "Rich in protein",
      "Ancient grains with a modern twist"
    ],
    process: [
      "Traditional recipes",
      "Carefully prepared",
      "Health-focused combinations"
    ],
    benefits: [
      "Sustains energy",
      "Aids digestion",
      "Promotes overall fitness"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 6. WholesomeBasketWidget
export const WholesomeBasketWidget = (props) => {
  const data = {
    title: "Wholesome Basket Combos",
    description:
      "A balanced selection of meals to meet your daily nutritional needs.",
    features: [
      "Variety of soups and porridges",
      "Curated selection",
      "Balanced nutrition"
    ],
    process: [
      "Carefully selected items",
      "Organized for ease of choice",
      "Quick delivery"
    ],
    benefits: [
      "Provides complete nutrition",
      "Convenient and healthy",
      "Variety of options"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 7. EssentialBoostWidget
export const EssentialBoostWidget = (props) => {
  const data = {
    title: "Essential Boost Combos",
    description:
      "Delivering a mix of superfoods to enhance overall wellness.",
    features: [
      "Vitamin rich",
      "Antioxidant packed",
      "Superfood ingredients"
    ],
    process: [
      "Expertly combined ingredients",
      "Quality assured",
      "Balanced flavor profiles"
    ],
    benefits: [
      "Boosts energy",
      "Strengthens immunity",
      "Enhances overall health"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 8. CustomizeComboWidget
export const CustomizeComboWidget = (props) => {
  const data = {
    title: "Customize Your Combo",
    description:
      "Build your own combo by selecting individual items such as soups, porridges, and beverages.",
    features: [
      "Flexible options",
      "Personalized nutrition",
      "Easy to build"
    ],
    process: [
      "Select your preferred items",
      "Customize as per your taste",
      "Review and order"
    ],
    benefits: [
      "Tailored to your needs",
      "Personalized meal plan",
      "Optimal nutritional balance"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 9. HealthySoupsWidget
export const HealthySoupsWidget = (props) => {
  const data = {
    title: "Healthy Soups",
    description:
      "Our healthy soups include a variety of nutritious options such as Spinach, Ginger Garlic, Pumpkin, and Broccoli Soups.",
    features: [
      "Fresh ingredients",
      "Rich in vitamins",
      "Delicious and healthy"
    ],
    process: [
      "Carefully selected recipes",
      "Slow-cooked for flavor",
      "Prepared with care"
    ],
    benefits: [
      "Boosts immunity",
      "Supports digestion",
      "Enhances flavor"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 10. HealthyPorridgesWidget
export const HealthyPorridgesWidget = (props) => {
  const data = {
    title: "Healthy Porridges",
    description:
      "Our range of porridges includes Oats, Ragi, Foxtail Millet, Bajra, and Jowar Porridge crafted for wholesome nutrition.",
    features: [
      "Rich in fiber",
      "Slow-cooked",
      "Nutrient dense"
    ],
    process: [
      "Expertly prepared",
      "Quality ingredients",
      "Balanced recipes"
    ],
    benefits: [
      "Sustained energy",
      "Promotes healthy digestion",
      "Provides essential nutrients"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 11. ImmunityBoostersWidget
export const ImmunityBoostersWidget = (props) => {
  const data = {
    title: "Immunity Boosters",
    description:
      "Meals designed to boost your immune system with rich vitamins and antioxidants.",
    features: [
      "Nutrient-dense ingredients",
      "High in vitamins",
      "Antioxidant loaded"
    ],
    process: [
      "Formulated with care",
      "Quality ingredients",
      "Balanced for optimal health"
    ],
    benefits: [
      "Strengthens immunity",
      "Enhances overall health",
      "Provides essential nutrients"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 12. SuperfoodWidget
export const SuperfoodWidget = (props) => {
  const data = {
    title: "Superfoods",
    description:
      "Discover our range of superfoods integrated into our menu, packed with essential nutrients.",
    features: [
      "Rich in nutrients",
      "High quality ingredients",
      "Essential for wellness"
    ],
    process: [
      "Carefully selected",
      "Expertly prepared",
      "Optimized for health"
    ],
    benefits: [
      "Supports overall health",
      "Boosts vitality",
      "Enhances nutritional intake"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// 13. AllProductsWidget
export const AllProductsWidget = (props) => {
  const data = {
    title: "All Products",
    description:
      "Explore our complete menu including Senior Power Combos, Women Combos, Soup Combos, Millet Combos, and more.",
    features: [
      "Diverse range of combos",
      "Healthy soups and porridges",
      "Customizable options"
    ],
    process: [
      "Easy navigation",
      "Detailed product info",
      "Seamless ordering experience"
    ],
    benefits: [
      "Complete nutrition",
      "Variety of choices",
      "Convenient and healthy"
    ]
  };
  return <ReusableWidget {...data} actions={props.actions} />;
};

// ─── Exporting All Widgets ─────────────────────────────────────────

export { ReusableWidget };
