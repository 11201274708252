// import React from "react";

// const ConfirmationWidget = ({ actions }) => {
//     return (
//         <div className="p-2">
//             <button className="p-2 m-1 bg-green-500 text-white rounded" onClick={actions.handleThankYou}>
//                 Yes, Thank You
//             </button>
//             <button className="p-2 m-1 bg-red-500 text-white rounded" onClick={actions.handleMoreHelp}>
//                 No, I need More Help
//             </button>
//         </div>
//     );
// };

// export default ConfirmationWidget;
import React from "react";
// import "./ChatWidgets.css"; // Shared CSS file
import "../ChatWidgets.css"; // Shared CSS file

const ConfirmationWidget = ({ actions }) => {
    return (
        <div className="widget-container">
            <button className="widget-button green" onClick={actions.handleThankYou}>
                ✅ Yes, Thank You
            </button>
            <button className="widget-button red" onClick={actions.handleMoreHelp}>
                ❌ No, I need More Help
            </button>
        </div>
    );
};

export default ConfirmationWidget;
