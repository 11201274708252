
import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import conversationData from "../../../contants/conversationData";

const MessageParser = ({ children, actions, setState, createChatBotMessage }) => {
  const parse = (message) => {
    // 1) Normalize user input
    const lowerCaseMessage = message.toLowerCase().trim();

    // 2) Attempt to find a match in conversationData
    for (const rule of conversationData) {
      // Check if any keyword in rule.keywords is included in the user's message
      const matched = rule.keywords.some((kw) => lowerCaseMessage.includes(kw));
      if (matched) {
        // If the rule type is "action", call the corresponding action
        if (rule.type === "action") {
          if (actions[rule.actionName]) {
            actions[rule.actionName](message); 
            return;
          }
        }
        // If the rule type is "response", send the response text and trigger action if provided
        else if (rule.type === "response") {
          actions.handleGenericResponse(rule.response, message);
          // Trigger action if actionName is defined and available
          if (rule.actionName && actions[rule.actionName]) {
            setTimeout(() => {
              actions[rule.actionName](message);
            }, 3000);
          }
          return;
        }
      }
    }

    // 3) Fallback if no rule matched
    const fallbackReply = "Sorry, I didn't understand that. Could you please rephrase?";
    actions.handleGenericResponse(fallbackReply, message);
  };

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          parse,
          actions,
          setState,
          createChatBotMessage
        })
      )}
    </div>
  );
};

export default MessageParser;
