// conversationData.js

const conversationData = [
    // --- Greetings & Basic ---
    {
      type: "response",
      keywords: ["hi", "hai", "hello", "heelllooo", "hey", "hii", "hiii", "gm", "good morning", "good afternoon", "good evening", "good night", "howdy", "whats up", "yo", "sup", "namaste", "vanakkam", "hola", "bonjour", "ciao", "salaam", "welcome", "wassup", "morning", "afternoon", "evening"],
      response: "Hello! How can I assist you today?"
    },
    // --- Product Specific (Static Responses) ---
    {
      type: "response",
      keywords: ["spinach"],
      response: "Palakura soup crafted with nutrient-dense spinach – a vitamin-packed, heart-healthy twist to your usual curries.",
       actionName: "handleOrderNow"
    },
    {
      type: "response",
      keywords: ["saggubiyyam"],
      response: "Antioxidant-rich sago porridge that aids digestion and strengthens muscles, offering a tasty, gluten-free boost."
      ,actionName: "handleOrderNow"
    },
    {
      type: "response",
      keywords: ["ragi"],
      response: "Organic ragi malt blended with milk and jaggery, delivering calcium and anti-ageing benefits in every sip."
    ,actionName: "handleOrderNow"
    },
    {
      type: "response",
      keywords: ["ambali"],
      response: "Traditional ragi ambali with fermented rice and spiced buttermilk, refreshing and believed to help manage diabetes."
    ,actionName: "handleOrderNow"
    },
    {
      type: "response",
      keywords: ["oats"],
      response: "Creamy oats porridge with buttermilk – a cool, refreshing beverage perfect for summer days."
    ,actionName: "handleOrderNow"
    },
    {
      type: "response",
      keywords: ["carrot"],
      response: "Carrot milk enriched with fresh carrots and desi milk to boost immunity and delight your taste buds."
   ,actionName: "handleOrderNow"
    },
    {
      type: "response",
      keywords: ["ginger"],
      response: "Ginger garlic soup combining robust flavors with heart-healthy benefits and a warming spice kick."
    ,actionName: "handleOrderNow"
    },
    {
      type: "response",
      keywords: ["pumpkin"],
      response: "Vitamin-rich pumpkin soup that offers plump health benefits in a delicious, comforting bowl."
    ,actionName: "handleOrderNow"
    },
    {
      type: "response",
      keywords: ["broccoli"],
      response: "Broccoli soup loaded with antioxidants, supporting memory and overall vitality."
    ,actionName: "handleOrderNow"
    },
    {
        type: "response",
        keywords: ["moringa"],
        response: "Moringa soup is a warm and comforting dish that combines rich flavors with a powerhouse of nutrients. This creamy soup is not only delightful to your taste buds but also offers significant health benefits"
      ,actionName: "handleOrderNow"
      },
      {
        type: "response",
        keywords: ["jowar"],
        response: "Jowar porridge, made from sorghum, is a hearty and wholesome dish that combines rich flavors with exceptional nutritional value. This porridge provides several health benefits"
      ,actionName: "handleOrderNow"
      },
      {
        type: "response",
        keywords: ["sattu"],
        response: "Sattu Beverage is a refreshing and wholesome drink made with roasted gram flour, offering a perfect balance of taste and nutrition's. "
      ,actionName: "handleOrderNow"
      },
      {
        type: "response",
        keywords: ["foxtail"],
        response: "Foxtail millet porridge is a nutritious and delicious dish that combines the wholesome goodness of foxtail millet with a creamy texture. This porridge offers numerous health benefits" 
      ,actionName: "handleOrderNow"
      },
      {
        type: "response",
        keywords: ["little"],
        response: "Little Millet Soup is a delicious and nutritious option for health-conscious individuals. Packed with iron to boost vitality, rich in antioxidants to enhance immunity, and supportive of heart health, it’s a wholesome choice for any time of day. " 
      ,actionName: "handleOrderNow"
      },
      {
        type: "response",
        keywords: ["Bajra"],
        response: "Bajra porridge, made from pearl millet, is a wholesome and nutritious dish that's perfect for starting your day on a healthy note. This creamy porridge is not only delicious but also packed with numerous health benefits." 
      ,actionName: "handleOrderNow"
      },
    
    // --- Action-Based Rules ---
    {
      type: "action",
      keywords: ["shipping", "delivery policy"],
      actionName: "handleShippingPolicy"
    },
    {
      type: "action",
      keywords: ["order now", "place order", "buy now", "order", "buy", "purchase", "add to cart","product","products"],
      actionName: "handleOrderNow"
    },
    {
      type: "action",
      keywords: ["readytocook", "ready to cook", "cook now", "cook"],
      actionName: "handleReadyToCook"
    },
    {
      type: "action",
      keywords: ["readytoeat", "ready to eat", "eat now", "eat"],
      actionName: "handleReadyToEat"
    },
    {
      type: "action",
      keywords: ["whatsapp"],
      actionName: "handleWhatsAppSupport"
    },
    {
      type: "action",
      keywords: ["call", "phone","contact"],
      actionName: "handleCallSupport"
    },
    {
      type: "action",
      keywords: ["track", "track order", "where is my order"],
      actionName: "handleTrackOrder"
    },
    {
      type: "action",
      keywords: ["support", "help"],
      actionName: "handleSupportHelp"
    },
    {
      type: "action",
      keywords: ["thank"],
      actionName: "handleThankYou"
    },
    {
      type: "action",
      keywords: ["more help", "no"],
      actionName: "handleMoreHelp"
    },
    
    // --- New Product/Nutrition Queries ---
    {
      type: "action",
      keywords: ["child", "kid"],
      actionName: "handleChildrensProduct"
    },
    {
      type: "action",
      keywords: ["senior"],
      actionName: "handleSeniorCombos"
    },
    {
      type: "action",
      keywords: ["women"],
      actionName: "handleWomenCombos"
    },
    {
      type: "action",
      keywords: ["soup"],
      // To avoid matching "custom" cases, your ActionProvider can check further if needed.
      actionName: "handleHealthySoups"
    },
    {
      type: "action",
      keywords: ["porridge"],
      actionName: "handleHealthyPorridges"
    },
    {
      type: "action",
      keywords: ["immunity", "boost"],
      actionName: "handleImmunityBoosters"
    },
    {
      type: "action",
      keywords: ["customize", "build"],
      actionName: "handleCustomizeCombo"
    },
    {
      type: "action",
      keywords: ["all products", "complete menu"],
      actionName: "handleAllProducts"
    },
    {
      type: "action",
      keywords: ["superfood"],
      actionName: "handleSuperfood"
    },
    
    // --- General Questions (Static Responses) ---
    {
      type: "response",
      keywords: ["what is the jaavapot", "about jaavapot"],
      response: "The JaavaPot is a brand specializing in millet-based ready-to-cook and ready-to-eat products for a healthier lifestyle."
    },
    {
      type: "response",
      keywords: ["where is the jaavapot", "jaavapot located"],
      response: "The JaavaPot is based in Hyderabad, India."
    },
    {
      type: "response",
      keywords: ["jaavapot"],
      response: "The JaavaPot is a brand specializing in millet-based ready-to-cook and ready-to-eat products for a healthier lifestyle. It is based in Hyderabad, India."
    },
    {
      type: "response",
      keywords: ["what types of products", "products do you offer"],
      response: "We offer millet-based ready-to-cook meals, including soups, porridges and health-based combos."
    },
    {
      type: "response",
      keywords: ["suitable for all age", "all age products"],
      response: "Yes, our products are designed for kids, adults, and seniors."
    },
    {
      type: "response",
      keywords: ["use preservatives"],
      response: "No, our products are free from preservatives and artificial additives."
    },
    {
      type: "response",
      keywords: ["organic product"],
      response: "We use high-quality natural and certified organic ingredients."
    },
    {
      type: "response",
      keywords: ["physical store", "brick store"],
      response: "Currently, we operate online and deliver across various locations."
    },
    {
      type: "response",
      keywords: ["gluten free", "gluten-free"],
      response: "Yes, most of our millet-based products are gluten-free."
    },
    {
      type: "response",
      keywords: ["vegan"],
      response: "Many of our products are plant-based, but we recommend checking individual product details."
    },
    {
      type: "response",
      keywords: ["provide samples", "sample"],
      response: "We occasionally run sample giveaways. Follow us on social media for updates."
    },
    
    // --- Product-Specific Questions ---
    {
      type: "response",
      keywords: ["millet types", "what millet", "use"],
      response: "We use foxtail millet, Ragi millet, little millet, Bajra millet, Jowar millet and finger millet."
    },
    {
      type: "response",
      keywords: ["best-selling", "bestselling", "best selling"],
      response: "Our bestsellers include foxtail millet, Moringa Soup, little millet, and finger millet."
    },
    {
      type: "response",
      keywords: ["instant meal"],
      response: "Yes, we offer instant millet-based meal options that require minimal preparation."
    },
    {
      type: "response",
      keywords: ["sugar free", "sugar-free"],
      response: "Yes, we have sugar-free porridges and soups suitable for diabetics."
    },
    {
      type: "response",
      keywords: ["kids friendly", "kid friendly", "kids product"],
      response: "Tiny Tummy Combos porridges are great for kids."
    },
    {
      type: "response",
      keywords: ["bulk", "wholesale"],
      response: "Yes, we offer bulk purchase options. Contact us for details."
    },
    {
      type: "response",
      keywords: ["festival", "special"],
      response: "Yes, we release special festival packs during occasions like Diwali and Pongal."
    },
    {
      type: "response",
      keywords: ["shelf life"],
      response: "Most of our products have a shelf life of 6-12 months."
    },
    {
      type: "response",
      keywords: ["combo pack"],
      response: "Yes, we have several combo packs designed for different health needs."
    },
    {
      type: "response",
      keywords: ["ingredient list", "ingredients"],
      response: "Ingredient details are available on our product packaging and website."
    },
    
    // --- Health & Nutrition Questions ---
    {
      type: "response",
      keywords: ["weight management"],
      response: "Millets are rich in fiber and keep you full for longer, aiding weight management."
    },
    {
      type: "response",
      keywords: ["diabetic", "diabetics"],
      response: "Yes, our millet-based meals help regulate blood sugar levels."
    },
    {
      type: "response",
      keywords: ["digestion"],
      response: "Millets are high in fiber, which promotes better digestion and gut health."
    },
    {
      type: "response",
      keywords: ["heart health"],
      response: "Yes, our heart-friendly products are low in cholesterol and high in antioxidants."
    },
    {
      type: "response",
      keywords: ["pregnant"],
      response: "Yes, millets provide essential nutrients that support pregnancy."
    },
    {
      type: "response",
      keywords: ["soups for children", "children soup"],
      response: "Our best soups for children include the Millet Ragi Soup, Pumpkin Soup, and Broccoli Soup, which are nutritious and easy to digest."
    },
    {
      type: "response",
      keywords: ["very weak", "weak soup"],
      response: "If you're feeling weak, our Protein-Rich Millet Soup, Iron-Boosting Spinach Soup, and Immunity-Boosting Turmeric Soup are great options to help restore energy and strengthen immunity."
    },
    {
      type: "response",
      keywords: ["immunity boosting", "soup"],
      response: "Our Immunity-Boosting Turmeric milk and Ginger-garlic Soup are great choices."
    },
    {
      type: "response",
      keywords: ["digestion", "soup"],
      response: "Our Fiber-Rich Millet Soup and Ajwain-Flavored Soup are good for digestion."
    },
    {
      type: "response",
      keywords: ["bone health", "millet bone"],
      response: "Our Ragi-based products are rich in calcium and great for bone health."
    },
    {
      type: "response",
      keywords: ["brain health", "cognitive millet"],
      response: "Foxtail millet in our porridge mix help in cognitive function."
    },
    {
      type: "response",
      keywords: ["seniors product"],
      response: "Yes, our soft-textured porridges and light soups are suitable for seniors."
    },
    {
      type: "response",
      keywords: ["managing cholesterol", "cholesterol help"],
      response: "Millet fiber helps lower cholesterol. Try our heart-friendly millet soups."
    },
    {
      type: "response",
      keywords: ["eat millet every day", "daily millet"],
      response: "Yes, millets are highly nutritious and safe for daily consumption."
    },
    {
      type: "response",
      keywords: ["best for anemia", "anemia millet"],
      response: "Ragi millet is rich in iron and great for preventing anemia."
    },
    
    // --- Ordering & Delivery Questions ---
    {
      type: "response",
      keywords: ["place an order", "order how"],
      response: "You can order directly from our website, Zomato, or Swiggy."
    },
    {
      type: "response",
      keywords: ["ship internationally", "international"],
      response: "Currently, we deliver only within India."
    },
    {
      type: "response",
      keywords: ["delivery time"],
      response: "Standard delivery takes 3-5 business days."
    },
    {
      type: "response",
      keywords: ["cod", "cash on delivery"],
      response: "Yes, COD is available in select locations."
    },
    {
      type: "response",
      keywords: ["return", "exchange"],
      response: "We accept returns for damaged or incorrect items."
    },
    
    // --- Cooking & Preparation Questions ---
    {
      type: "response",
      keywords: ["make millet soup", "how to make soup"],
      response: "Just add hot water, stir well, and your soup is ready to serve. You can check instructions on the packet."
    },
    {
      type: "response",
      keywords: ["add vegetables", "vegetables ready-to-cook"],
      response: "Yes, you can customize the meals with your favorite vegetables."
    },
    {
      type: "response",
      keywords: ["microwave"],
      response: "Yes, most of our products can be prepared using a microwave."
    },
    {
      type: "response",
      keywords: ["store your products", "best way to store"],
      response: "Store in a cool, dry place in an airtight container."
    },
    
    // --- Additional Responses ---
    {
      type: "response",
      keywords: ["heart health", "soup"],
      response: "Broccoli soup, Oats porridge, and Bajra porridge are great for heart health."
    },
    {
      type: "response",
      keywords: ["boost my immunity"],
      response: "Broccoli soup and Pumpkin soup are rich in antioxidants and boost immunity."
    },
    {
      type: "response",
      keywords: ["diabetes"],
      response: "Ragi Ambali and Bajra Porridge have a low glycemic index, making them ideal for diabetes management."
    },
    {
      type: "response",
      keywords: ["weight loss"],
      response: "Foxtail Millet Porridge and Little Millet Soup help in weight management."
    },
    {
      type: "response",
      keywords: ["gluten-free"],
      response: "Yes! Ragi Ambali, Jowar Porridge, and Foxtail Millet Porridge are gluten-free."
    },
    {
      type: "response",
      keywords: ["kids"],
      response: "Pumpkin soup is rich in beta-carotene, great for children's nutrition."
    },
    {
      type: "response",
      keywords: ["price", "moringa soup"],
      response: "Moringa soup is priced at ₹150 per serving."
    },
    {
      type: "response",
      keywords: ["discount"],
      response: "Yes! We offer 10% off on orders above ₹500 and free shipping on orders above ₹1000."
    },
    {
      type: "response",
      keywords: ["sample pack"],
      response: "Yes! We have a Millet Starter Pack with different soups and porridges for ₹499."
    },
    {
      type: "response",
      keywords: ["source", "millets"],
      response: "Our millets are sourced from certified organic farms across India."
    },
    {
      type: "response",
      keywords: ["order online"],
      response: "Visit our website, thejaavapot.com, add products to the cart, and proceed to checkout."
    },
    {
      type: "response",
      keywords: ["delivery", "take"],
      response: "Delivery takes 2-10 days depending on your location."
    },
    {
      type: "response",
      keywords: ["festive", "ugadi", "offer","festival",],
      response: "Yes! We have special discounts on all ready-to-cook millet products. 1. Get a special combo offer! Buy any 2 millet products and get 1 product for free. Perfect for stocking up on healthy meals. 2. Looking for a bulk order? Enjoy exclusive discounts on large purchases. Contact us for special pricing! 3. Free Delivery for orders above Rs.1000/- all over India. 4. We offer 10% off on orders above ₹500." },
    
    ,{
      "type": "response",
      "keywords": ["bulk", "corporate", "wholesale", "large order"],
      "response": "Looking for a bulk order? Enjoy exclusive discounts on large purchases. Contact us for special pricing!"
    },
      {
      type: "response",
      keywords: ["return policy"],
      response: "We offer a 7-day return policy for unopened packages. Contact support for assistance."
    },
    {
      type: "response",
      keywords: ["subscribe", "subscription"],
      response: "Yes! We have a monthly millet subscription plan with special discounts."
    },
    {
      type: "response",
      keywords: ["collaborate", "collaboration","collab"],
      response: "Please enter your WhatsApp number, and our team will contact you for collaboration opportunities."
    },
    {
      type: "response",
      keywords: ["located", "location", "address", "offline"],
      response: "We are based in Hyderabad, 530006, but we deliver all over India."
    },
    {
      type: "response",
      keywords: ["feedback"],
      response: "You can leave a review on our website or WhatsApp us at our support number."
    },
    {
      "type": "response",
      "keywords": ["products", "soups", "porridges", "items", "menu", "options", "available"],
      "response": "We offer a variety of soups and porridges, including Broccoli Soup, Moringa Soup, Pumpkin Soup, Oats Porridge, and many more. Would you like recommendations based on your health needs?"
    },
    {
      "type": "response",
      "keywords": ["price", "cost", "how much", "rate", "charge", "expensive", "affordable"],
      "response": "Our soups and porridges are priced between ₹129 and ₹150. Let me know if you need details on a specific item!"
    },
    {
      "type": "response",
      "keywords": ["kids", "children", "for my child", "best for kids", "healthy for children"],
      "response": "For kids, we recommend Pumpkin Soup (rich in beta-carotene for growth), Oats Porridge (good for digestion and energy), and Sattu Beverage (a protein-packed energy booster)."
    },
    {
      "type": "response",
      "keywords": ["elderly", "old", "senior citizens", "grandparents", "for my parents"],
      "response": "For senior citizens, we recommend Ragi Ambali (rich in calcium for bone strength), Bajra Porridge (great for diabetes management and heart health), and Broccoli Soup (boosts immunity and aids detoxification)."
    },
    {
      "type": "response",
      "keywords": ["weight loss","weightloss", "slim", "metabolism", "fat burn", "low calorie", "healthy diet"],
      "response": "If you're looking to lose weight, try Foxtail Millet Porridge (supports metabolism and weight loss), Oats Porridge (keeps you full for longer), and Pumpkin Soup (low in calories and rich in nutrients)."
    },
    {
      "type": "response",
      "keywords": ["diabetes", "sugar control", "low glycemic", "blood sugar"],
      "response": "For diabetes management, we recommend Ragi Ambali (low glycemic index), Bajra Porridge (helps regulate blood sugar), and Little Millet Soup (rich in fiber and antioxidants)."
    },
    {
      "type": "response",
      "keywords": ["protein", "high protein", "muscle", "fitness", "gym"],
      "response": "For a high-protein diet, try Sattu Beverage (great for energy and digestion), Jowar Porridge (rich in protein and dietary fiber), and Ragi Ambali (great for muscle strength)."
    },
    {
      "type": "response",
      "keywords": ["immunity", "boost immunity", "strong immune system"],
      "response": "Boost your immunity with Broccoli Soup (rich in antioxidants), Moringa Soup (anti-inflammatory and nutrient-dense), and Pumpkin Soup (loaded with beta-carotene and vitamins)."
    },
    {
      "type": "response",
      "keywords": ["gluten-free", "no gluten", "allergy", "wheat-free"],
      "response": "We have several gluten-free options, including Ragi Ambali, Jowar Porridge, and Bajra Porridge. Let us know if you need more details!"
    },
    {
      "type": "response",
      "keywords": ["energy", "tired", "fatigue", "boost energy", "refreshing"],
      "response": "For an energy boost, try Sattu Beverage (rich in protein and cooling effect), Foxtail Millet Porridge (supports metabolism), and Oats Porridge (provides sustained energy)."
    },
    {
      "type": "response",
      "keywords": ["heart health", "healthy heart", "cholesterol", "BP", "cardiac", "blood pressure"],
      "response": "For heart health, we recommend Pumpkin Soup (heart-friendly nutrients), Bajra Porridge (supports heart health and blood circulation), and Oats Porridge (helps regulate cholesterol levels)."
    }
,

    
    
    
    
    
    
    
    
    {
  "type": "response",
  "keywords": ["order", "buy", "purchase", "how to buy", "where to order", "website"],
  "response": "You can order our products directly from our website at thejaavapot.com. Let me know if you need any help!"
}

    
  ];
  
  export default conversationData;
  