import React, { useState } from "react";
import { toast } from "react-toastify";
import "./ChatFoodCard.css"; // Create/modify this CSS file for styling
import { useDispatchCart,useCart } from "../../../../../../store/ContextReducer";

const ChatFoodCard = ({ foodItem, userCity,actions  }) => {
  const dispatch = useDispatchCart();
  const cartData = useCart();
  const [qty, setQty] = useState(1);
  const [size, setSize] = useState(""); // Optional: Use if you need size selection

  const handleIncrement = () => {
    setQty((prev) => prev + 1);
  };

  const handleDecrement = () => {
    setQty((prev) => (prev > 1 ? prev - 1 : prev));
  };

    const handleAddToCart = async () => {
      console.log('297',foodItem);
      
        const finalPrice = qty * parseInt(foodItem.details[0].price || 0);
        const basicPrice =  parseInt(foodItem.details[0].price || 0);
  
        // if (foodItem.maincategory === "ReadyToEat") {
        //   console.log("Checking city",userCity)
        //   if (myCity && myCity.toLowerCase() === "hyderabad") {
        //     addFoodToCart(finalPrice,basicPrice);
        //   } else {
        //     toast.error("We apologize, but we are currently only serving the Hyderabad location.");
        //   }
        // } else if (foodItem.maincategory === "ReadyToCook") {
        //   addFoodToCart(finalPrice,basicPrice);
        // }else if (foodItem.maincategory === "Combos") {
          addFoodToCart(finalPrice,basicPrice);
        // }
    };
  
    const addFoodToCart = async (finalPrice,basicPrice) => {
      let food = cartData.find(item => item.id === foodItem._id);
  
      if (food) {
        if (food.size === size) {
          await dispatch({
            type: "UPDATE",
            id: foodItem._id,
            price: finalPrice,
            qty,
          });
        } else {
          await dispatch({
            type: "ADD",
            id: foodItem._id,
            name: foodItem.itemname,
            price: finalPrice,
            qty,
            size,
            img: foodItem.imagepath,
            basicPrice,
            maincategory:foodItem.maincategory,
            category:foodItem.category
          });
        }
      } else {
        await dispatch({
          type: "ADD",
          id: foodItem._id,
          name: foodItem.itemname,
          description: foodItem.description,
          price: finalPrice,
          qty,
          size,
          img: foodItem.imagepath,
          basicPrice,
          maincategory:foodItem.maincategory,
          category:foodItem.category
        });
      }
      toast.success(`Added item ${foodItem.itemname} to your cart`);

       // Create a user message via ActionProvider.
       if (actions && typeof actions.createUserMessage === "function") {
        actions.createUserMessage(`Add ${foodItem.itemname} to the cart`);
      }
      // Create a bot response via ActionProvider.
      if (actions && typeof actions.productAddedResponse === "function") {
        actions.productAddedResponse(foodItem.itemname);
      }
  

      setTimeout(() => {
        actions.promptMoreProducts(); // This should trigger MoreProductsPromptWidget
      }, 5000);
    };


  return (
    <div className="chat-food-card">
      <div className="chat-food-card-header">
        <img
          src={foodItem.imagepath}
          alt={foodItem.itemname}
          className="chat-food-card-img"
        />
        <div className="chat-food-card-info">
          <h4>{foodItem.itemname}</h4>
          <p className="chat-food-card-desc">{foodItem.description}</p>
          <p className="chat-food-card-price">
            ₹{qty * parseInt(foodItem.details[0].price || "0", 10)}
          </p>
        </div>
      </div>
      <div className="chat-food-card-controls">
        <button onClick={handleDecrement} className="chat-qty-btn">
          -
        </button>
        <span className="chat-qty-display">{qty}</span>
        <button onClick={handleIncrement} className="chat-qty-btn">
          +
        </button>
      </div>
      <button onClick={handleAddToCart} className="chat-add-to-cart-btn">
        Add to Cart
      </button>
    </div>
  );
};

export default ChatFoodCard;
