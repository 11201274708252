import React, { useState } from "react";
import '../ChatWidgets.css'

const TrackOrderEmailWidget = ({ actions }) => {
    const [email, setEmail] = useState("");

    const handleSubmit = () => {
        if (email.trim()) {
            actions.handleFetchOrdersByEmail(email);
        }
    };

    return (
        <div className="widget-container">
            <input 
                type="email" 
                placeholder="Enter your email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                className="widget-input"
            />
            <button className="widget-button" onClick={handleSubmit}>
                📩 Fetch Orders
            </button>
        </div>
    );
};

export default TrackOrderEmailWidget;
