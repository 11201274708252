// OrderPlacedWidget.jsx
import React from "react";

import "../../ChatWidgets.css";

const OrderPlacedWidget = () => {




  return (
    <div className="widget-container">
      <h3>Order Placed Successfully!</h3>
      <p>Your order has been placed and will be delivered soon.</p>
     
    </div>
  );
};

export default OrderPlacedWidget;
