import { createChatBotMessage } from "react-chatbot-kit";
import Avatar from "./utilities/Avatar";
import OptionsWidget from "./widgets/OptionsWidget";
import SupportOptionsWidget from "./widgets/contact/SupportOptionsWidget";
import TrackOrderEmailWidget from "./widgets/trackorder/TrackOrderEmailWidget ";
import OrderSelectionWidget from "./widgets/trackorder/OrderSelectionWidget";
import OrderStatusWidget from "./widgets/trackorder/OrderStatusWidget";
import ConfirmationWidget from "./widgets/common/ConfirmationWidget";
import WhatsAppWidget from "./widgets/contact/WhatsAppWidget";
import CallSupportWidget from "./widgets/common/CallSupportWidget";
// import StayUpdatedWidget from "./widgets/stayupdated/StayUpdatedWidget";
import StayUpdatedWidget from "./widgets/stayupdated/StayUpdatedWidget ";
import PartnerCollabWidget from "./widgets/collab/PartnerCollabWidget ";
import CustomHeader from "./utilities/CustomHeader";
import UserAvatar from "./utilities/UserAvatar";

// Import new reusable product/nutrition widget components
import { 
  ChildrensProductWidget, 
  SeniorCombosWidget, 
  WomenCombosWidget, 
  SoupCombosWidget, 
  MilletCombosWidget, 
  WholesomeBasketWidget, 
  EssentialBoostWidget, 
  CustomizeComboWidget, 
  HealthySoupsWidget, 
  HealthyPorridgesWidget, 
  ImmunityBoostersWidget, 
  SuperfoodWidget, 
  AllProductsWidget 
} from "../components/widgets/advanced/components/ReusableWidget";
import OrderNowWidget from "./widgets/order/OrderNowWidget";
import ReadyToCookWidget from "./widgets/order/product/ReadyToCookWidget";
import ReadyToEatWidget from "./widgets/order/product/ReadyToEatWidget";
import MoreProductsPromptWidget from "./widgets/order/product/MoreProductsPromptWidget";
import ConfirmOrderWidget from "./widgets/order/product/ConfirmOrderWidget";
import ChatOrderSummaryWidget from "./widgets/order/product/ChatOrderSummaryWidget";
import OrderPlacedWidget from "./widgets/order/product/OrderPlacedWidget";

const config = {
  botName: "ReactBot",
  initialMessages: [
    createChatBotMessage("How can I assist you today?", {
      widget: "optionsWidget",
    }),
  ],
  customComponents: {
    header: () => <CustomHeader />,
    botAvatar: (props) => <Avatar {...props} />,
    userAvatar: (props) => <UserAvatar {...props} />,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#5ccc9d",
    },
  },
  widgets: [
    {
      widgetName: "optionsWidget",
      widgetFunc: (props) => <OptionsWidget {...props} />,
    },
    {
      widgetName: "stayUpdatedWidget",
      widgetFunc: (props) => <StayUpdatedWidget {...props} />,
    },
    {
      widgetName: "partnerCollabWidget",
      widgetFunc: (props) => <PartnerCollabWidget {...props} />,
    },
    {
      widgetName: "confirmationWidget",
      widgetFunc: (props) => <ConfirmationWidget {...props} />,
    },
    {
      widgetName: "supportOptionsWidget",
      widgetFunc: (props) => <SupportOptionsWidget {...props} />,
    },
    {
      widgetName: "whatsappWidget",
      widgetFunc: (props) => <WhatsAppWidget {...props} />,
    },
    {
      widgetName: "callSupportWidget",
      widgetFunc: (props) => <CallSupportWidget {...props} />,
    },
    {
      widgetName: "trackOrderEmailWidget",
      widgetFunc: (props) => <TrackOrderEmailWidget {...props} />,
    },
    {
      widgetName: "orderSelectionWidget",
      widgetFunc: (props) => <OrderSelectionWidget {...props} />,
    },
    {
      widgetName: "orderStatusWidget",
      widgetFunc: (props) => <OrderStatusWidget {...props} />,
    },
    // New Product / Nutrition Widgets
    {
      widgetName: "childrensProductWidget",
      widgetFunc: (props) => <ChildrensProductWidget {...props} />,
    },
    {
      widgetName: "seniorCombosWidget",
      widgetFunc: (props) => <SeniorCombosWidget {...props} />,
    },
    {
      widgetName: "womenCombosWidget",
      widgetFunc: (props) => <WomenCombosWidget {...props} />,
    },
    {
      widgetName: "soupCombosWidget",
      widgetFunc: (props) => <SoupCombosWidget {...props} />,
    },
    {
      widgetName: "milletCombosWidget",
      widgetFunc: (props) => <MilletCombosWidget {...props} />,
    },
    {
      widgetName: "wholesomeBasketWidget",
      widgetFunc: (props) => <WholesomeBasketWidget {...props} />,
    },
    {
      widgetName: "essentialBoostWidget",
      widgetFunc: (props) => <EssentialBoostWidget {...props} />,
    },
    {
      widgetName: "customizeComboWidget",
      widgetFunc: (props) => <CustomizeComboWidget {...props} />,
    },
    {
      widgetName: "healthySoupsWidget",
      widgetFunc: (props) => <HealthySoupsWidget {...props} />,
    },
    {
      widgetName: "healthyPorridgesWidget",
      widgetFunc: (props) => <HealthyPorridgesWidget {...props} />,
    },
    {
      widgetName: "immunityBoostersWidget",
      widgetFunc: (props) => <ImmunityBoostersWidget {...props} />,
    },
    {
      widgetName: "superfoodWidget",
      widgetFunc: (props) => <SuperfoodWidget {...props} />,
    },
    {
      widgetName: "allProductsWidget",
      widgetFunc: (props) => <AllProductsWidget {...props} />,
    },
    // order now route
    {
      widgetName: "orderNowWidget",
      widgetFunc: (props) => <OrderNowWidget {...props} />,
    },
    {
      widgetName: "readyToCookWidget",
      widgetFunc: (props) => <ReadyToCookWidget {...props} />,
    },
    {
      widgetName: "readyToEatWidget",
      widgetFunc: (props) => <ReadyToEatWidget {...props} />,
    },
    {
      widgetName: "moreProductsPromptWidget",
      widgetFunc: (props) => <MoreProductsPromptWidget {...props} />,
    },
    {
      widgetName: "confirmOrderWidget",
      widgetFunc: (props) => <ConfirmOrderWidget {...props} />,
    },
    {
      widgetName: "chatOrderSummaryWidget",
      widgetFunc: (props) => <ChatOrderSummaryWidget {...props} />,
    },
    {
      widgetName: "orderPlacedWidget",
      widgetFunc: (props) => <OrderPlacedWidget {...props} />,
    },
  ],
};

export default config;

