import React from "react";

const SupportOptionsWidget = ({ actions }) => {
    return (
        <div className="widget-container">
            <button className="widget-button green" onClick={actions.handleWhatsAppSupport}>
                📲 Chat on WhatsApp
            </button>
            <button className="widget-button blue" onClick={actions.handleCallSupport}>
                📞 Call Support
            </button>
        </div>
    );
};

export default SupportOptionsWidget;
