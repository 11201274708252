import React from "react";
import logo from '../../../../assets/img/white-logo.png'
import logo01 from '../../../../assets/img/chatbot/Garobot.png'

const CustomHeader = () => {
    return (
        <div style={{
            backgroundColor: " #75a874",
            // #FD7E4B
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            color: "white",
            fontSize: "14px",
            fontWeight: "600",
            padding: "20px",
            paddingTop:'10px',
            paddingBottom:'10px',
        }}>
            <img 
                src= {logo}/* Replace with your actual logo */
                alt="Chatbot Logo" 
                style={{ width: "150px", height: "50px", marginRight: "10px" }} 
            />
            <div style={{paddingTop:'20px'}}>

            {/* Support */}      
            </div>
            <img 
                src= {logo01}/* Replace with your actual logo */
                alt="Chatbot Logo" 
                style={{ width: "100px", height: "40px", marginLeft: "70px" }} 
            />
        </div>
    );
};

export default CustomHeader;
