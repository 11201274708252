// import React, { useState } from "react";
// import { Chatbot } from "react-chatbot-kit";
// import "react-chatbot-kit/build/main.css";

// import config from "./components/config";
// import MessageParser from "./components/MessageParser";
// import ActionProvider from "./components/ActionProvider";

// const ChatBotToggle = () => {
//   const [isChatbotVisible, setIsChatbotVisible] = useState(false);

//   const toggleChatbot = () => {
//     setIsChatbotVisible((prev) => !prev);
//   };

//   return (
//     <>
//       {/* Toggle Button */}
//       <button
//         aria-label={isChatbotVisible ? "Close chatbot" : "Open chatbot"}
//         title={isChatbotVisible ? "Close chatbot" : "Open chatbot"}
//         onClick={toggleChatbot}
//         style={{
//           position: "fixed",
//           bottom: "20px",
//           right: "20px",
//           // backgroundColor: "#007bff",
//           backgroundColor: "#FD7E4B",
//           // color: "white",
//           color: 'black', /* Icon color */
//           borderRadius: "50%",
//           width: "60px",
//           height: "60px",
//           border: "none",
//           cursor: "pointer",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//           fontSize: "20px",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           transition: "transform 0.3s ease",
//           transform: isChatbotVisible ? "rotate(180deg)" : "rotate(0deg)",
//         }}
//       >
//         {isChatbotVisible ? "❌" : "💬"}
//       </button>

//       {/* Chatbot */}
//       <div
//         style={{
//           position: "fixed",
//           bottom: isChatbotVisible ? "90px" : "-400px",
//           right: "20px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//           borderRadius: "30px",
//           overflow: "hidden",
//           transition: "bottom 0.3s ease",
//           opacity: isChatbotVisible ? 1 : 0,
//           pointerEvents: isChatbotVisible ? "auto" : "none",
//           background: "linear-gradient(135deg, #FDF0DD, #f5dcb6)",
//         //   width: "350px",
//         }}
//         aria-live="polite"
//       >
//         <Chatbot config={config} messageParser={MessageParser} actionProvider={ActionProvider} />
//       </div>
//     </>
//   );
// };

// export default ChatBotToggle;

import React, { useState } from "react";
import { FaComments, FaTimes } from "react-icons/fa"; // Importing React Icons
import { Chatbot } from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";

import config from "./components/config";
import MessageParser from "./components/MessageParser";
import ActionProvider from "./components/ActionProvider";

const ChatBotToggle = () => {
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);

  const toggleChatbot = () => {
    setIsChatbotVisible((prev) => !prev);
  };

  return (
    <>
      {/* Toggle Button */}
      <button
        aria-label={isChatbotVisible ? "Close chatbot" : "Open chatbot"}
        title={isChatbotVisible ? "Close chatbot" : "Open chatbot"}
        onClick={toggleChatbot}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#FD7E4B", // Background color
          color: "black", // Icon color
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          border: "none",
          cursor: "pointer",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          fontSize: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "transform 0.3s ease",
          transform: isChatbotVisible ? "rotate(180deg)" : "rotate(0deg)",
        }}
      >
        {/* Toggle between icons */}
        {isChatbotVisible ? <FaTimes style={{ color: "white", fontSize: "24px" }} /> : <FaComments style={{ color: "white", fontSize: "24px" }} />}
      </button>

      {/* Chatbot */}
      <div
        style={{
          position: "fixed",
          bottom: isChatbotVisible ? "90px" : "-400px",
          right: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "30px",
          overflow: "hidden",
          transition: "bottom 0.3s ease",
          opacity: isChatbotVisible ? 1 : 0,
          pointerEvents: isChatbotVisible ? "auto" : "none",
          background: "linear-gradient(135deg, #FDF0DD, #f5dcb6)",
        }}
        aria-live="polite"
      >
        <Chatbot config={config} messageParser={MessageParser} actionProvider={ActionProvider} />
      </div>
    </>
  );
};

export default ChatBotToggle;
