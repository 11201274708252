// import React, { useState, useEffect } from "react";
// import { ApiPath } from "../../../../../../config";
// import "./ProductWidgets.css"; // Ensure styling is consistent with your chatbot UI

// const ReadyToEatWidget = ({ actions, payload }) => {
//   const [products, setProducts] = useState([]);

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await fetch(`${ApiPath}jaavapotmenu/api/products`);
//         const data = await response.json();
//         // Filter products with maincategory "ReadyToEat"
//         const filtered = data.filter(
//           (product) => product.maincategory === "ReadyToEat"
//         );
//         setProducts(filtered);
//       } catch (error) {
//         console.error("Error fetching Ready To Eat products:", error);
//       }
//     };
//     fetchProducts();
//   }, []);

//   return (
//     <div className="chatbot-product-widget">
//       <h3>Ready To Eat Products</h3>
//       {products.length > 0 ? (
//         <ul className="product-list">
//           {products.map((product) => (
//             <li key={product._id} className="product-item">
//               <img
//                 src={product.imagepath}
//                 alt={product.itemname}
//                 className="product-image"
//               />
//               <div className="product-info">
//                 <h4>{product.itemname}</h4>
//                 <p>{product.description}</p>
//                 {product.details && product.details.length > 0 && (
//                   <p>Price: Rs. {product.details[0].price}</p>
//                 )}
//               </div>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <p>No Ready To Eat products available.</p>
//       )}
//     </div>
//   );
// };

// export default ReadyToEatWidget;

import React, { useState, useEffect } from "react";
import { ApiPath } from "../../../../../../config";
import ChatFoodCard from "./ChatFoodCard"; // Ensure the correct path is set
import "./ProductWidgets.css";

const ReadyToEatWidget = ({ actions, payload, userCity }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${ApiPath}jaavapotmenu/api/products`);
        const data = await response.json();
        // Filter for products with maincategory "ReadyToEat"
        const filtered = data.filter(
          (product) => product.maincategory === "ReadyToEat"
        );
        setProducts(filtered);
      } catch (error) {
        console.error("Error fetching Ready To Eat products:", error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="chatbot-product-widget">
      <h3>Ready To Eat Products</h3>
      {products.length > 0 ? (
        <div className="chat-food-card-list">
          {products.map((product) => (
            <ChatFoodCard
              key={product._id}
              foodItem={product}
              userCity={userCity}
              actions={actions}  
            />
          ))}
        </div>
      ) : (
        <p>No Ready To Eat products available.</p>
      )}
    </div>
  );
};

export default ReadyToEatWidget;
