// // ChatOrderSummaryWidget.jsx
// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import axios from "axios";
// import "../../ChatWidgets.css";
// import { ApiPath } from "../../../../../../config";
// import { useCart, useDispatchCart } from "../../../../../../store/ContextReducer";

// const ChatOrderSummaryWidget = (props) => {
//   // Extract payload (including selectedAddress and any additional order details)
//   const { selectedAddress } = props.payload || {};
//   // Also extract actions passed from the ActionProvider.
//   const { actions } = props;
  
//   // Retrieve dynamic cart data from your context.
//   const menuData = useCart();
//   const dispatch = useDispatchCart();

//   // Calculate subtotal from cart items.
//   const calculateSubtotal = () =>
//     menuData.reduce(
//       (sum, item) => sum + Number(item.basicPrice) * Number(item.qty),
//       0
//     );
//   const subtotal = calculateSubtotal();
//   const GST = subtotal * 0.18; // 18% GST

//   // Calculate delivery charges based on the address.
//   const calculateDeliveryCharges = () => {
//     if (selectedAddress && selectedAddress.city) {
//       if (selectedAddress.city.toLowerCase() === "hyderabad") {
//         return subtotal > 1000 ? 0 : 50;
//       }
//     }
//     return 100; // Default for other cities.
//   };
//   const deliveryCharges = calculateDeliveryCharges();

//   const finalAmount =
//     Math.round(1* 100) / 100;

//   // handlePlaceOrder: Processes order placement using Razorpay.
//   const handlePlaceOrder = async () => {
//     if (!selectedAddress) {
//       toast.error("No delivery address provided.");
//       return;
//     }
//     if (!menuData || menuData.length === 0) {
//       toast.error("Your cart is empty.");
//       return;
//     }
//     try {
//       // Step 1: Retrieve payment key.
//       const { data: { key } } = await axios.get(`${ApiPath}jaavapotpayment/api/getkey`);

//       // Step 2: Create an order via your payment API.
//       const { data: { order } } = await axios.post(`${ApiPath}jaavapotpayment/checkout`, {
//         amount: finalAmount,
//       });

//       // Step 3: Configure Razorpay options.
//       const options = {
//         key,
//         amount: order.amount,
//         currency: "INR",
//         name: "Jaavapot",
//         description: "Food ordering",
//         order_id: order.id,
//         handler: async function (response) {
//           const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
//           const paymentDetail = {
//             totalAmount: finalAmount,
//             orderId: razorpay_order_id,
//             paymentId: razorpay_payment_id,
//             paymentSignature: razorpay_signature,
//           };

//           // Step 4: Submit order data to your backend.
//           const orderResponse = await axios.post(`${ApiPath}jaavapotorders/api/orders`, {
//             order_data: [menuData, selectedAddress,paymentDetail],
//             email: "1mohsinsangram@gmail.com",
//           });
//           // Step 5: Notify the kitchen via email.
//           const emailResponse = await axios.post(
//             `${ApiPath}jaavapotemailnotification/send-email`,
//             {
//               emailid: "bamohsin@pagoanalytics.com",
//               // emailid: "haseelaboina@pagoanalytics.com",
//               order_data: [menuData, selectedAddress],
//             }
//           );
//           if (orderResponse.status === 200 && emailResponse.status === 200) {
//             // Clear cart.
//             dispatch({ type: "DROP" });
//             toast.success("Your Order is Successfully Placed and will be delivered soon.");
//             // Here, call the action function 'orderPlaced' from ActionProvider.
//             // if (actions && typeof actions.orderPlaced === "function") {
//               actions.orderPlaced();
//             // }
//           }
//         },
//         prefill: {
//           name: selectedAddress.name,
//           email: selectedAddress.email,
//           contact: selectedAddress.phone,
//         },
//         notes: {
//           city: selectedAddress.city,
//           address: selectedAddress.location,
//           pincode: selectedAddress.pincode,
//           landmark: selectedAddress.landmark,
//         },
//         theme: { color: "#FD7E4B" },
//       };

//       // Step 6: Open Razorpay modal.
//       const rzp = new window.Razorpay(options);
//       rzp.open();
//     } catch (error) {
//       console.error("Order placement error:", error);
//       toast.error("Error placing your order. Please try again.");
//     }
//   };

//   return (
//     <div className="widget-container">
//       <h3>Order Summary</h3>
//       <div>
//         <h4>Products:</h4>
//         {menuData.length > 0 ? (
//           <ul>
//             {menuData.map((item, index) => (
//               <li key={index}>
//                 {item.name} – Qty: {item.qty} – Price: ₹{item.price}
//               </li>
//             ))}
//           </ul>
//         ) : (
//           <p>No products in cart.</p>
//         )}
//       </div>
//       <div className="summary-row">
//         <p>Subtotal:</p>
//         <p>₹{subtotal}</p>
//       </div>
//       <div className="summary-row">
//         <p>GST (18%):</p>
//         <p>₹{GST.toFixed(2)}</p>
//       </div>
//       <div className="summary-row">
//         <p>Delivery Charges:</p>
//         <p>₹{deliveryCharges}</p>
//       </div>
//       <div className="summary-row total-amount">
//         <p>Total Amount:</p>
//         <p>₹{finalAmount}</p>
//       </div>
//       <div className="address-container">
//         <h4>Delivery Address</h4>
//         {selectedAddress ? (
//           <div>
//             <p>{selectedAddress.name}</p>
//             <p>
//               {selectedAddress.location}, {selectedAddress.city},{" "}
//               {selectedAddress.state} – {selectedAddress.pincode}
//             </p>
//             <p>{selectedAddress.phone}</p>
//             {selectedAddress.email && <p>{selectedAddress.email}</p>}
//           </div>
//         ) : (
//           <p>No address provided.</p>
//         )}
//       </div>
//       <button onClick={handlePlaceOrder} className="widget-button">
//         Place Order
//       </button>
//     </div>
//   );
// };

// export default ChatOrderSummaryWidget;

// ChatOrderSummaryWidget.jsx
import React from "react";
import { toast } from "react-toastify";

import axios from "axios";
import "../../ChatWidgets.css";
import { ApiPath } from "../../../../../../config";
import { useCart, useDispatchCart } from "../../../../../../store/ContextReducer";

const ChatOrderSummaryWidget = (props) => {
  // Extract payload from props.
  const { selectedAddress } = props.payload || {};
  // Also extract actions from props.
  const { actions } = props;

  const menuData = useCart();
  const dispatch = useDispatchCart();


  // Calculate subtotal from cart items.
  const calculateSubtotal = () =>
    menuData.reduce(
      (sum, item) => sum + Number(item.basicPrice) * Number(item.qty),
      0
    );
  const subtotal = calculateSubtotal();
  const GST = subtotal * 0.18;

  // Calculate delivery charges based on the address.
  const calculateDeliveryCharges = () => {
    if (selectedAddress && selectedAddress.city) {
      if (selectedAddress.city.toLowerCase() === "hyderabad") {
        return subtotal > 1000 ? 0 : 50;
      }
    }
    return 100;
  };
  const deliveryCharges = calculateDeliveryCharges();

  // const finalAmount = Math.round((1) * 100) / 100;
  const finalAmount = Math.round((subtotal + GST + deliveryCharges) * 100) / 100;

  const handlePlaceOrder = async () => {
    if (!selectedAddress) {
      toast.error("No delivery address provided.");
      return;
    }
    if (!menuData || menuData.length === 0) {
      toast.error("Your cart is empty.");
      return;
    }
    try {
      const { data: { key } } = await axios.get(`${ApiPath}jaavapotpayment/api/getkey`);
      const { data: { order } } = await axios.post(`${ApiPath}jaavapotpayment/checkout`, {
        amount: finalAmount,
      });
      const options = {
        key,
        amount: order.amount,
        currency: "INR",
        name: "Jaavapot",
        description: "Food ordering",
        order_id: order.id,
        handler: async function (response) {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
          const paymentDetail = {
            totalAmount: finalAmount,
            orderId: razorpay_order_id,
            paymentId: razorpay_payment_id,
            paymentSignature: razorpay_signature,
          };

          const orderResponse = await axios.post(`${ApiPath}jaavapotorders/api/orders`, {
            order_data: [menuData, selectedAddress, paymentDetail],
            email: selectedAddress.delivery_email,
          });
          const emailResponse = await axios.post(
            `${ApiPath}jaavapotemailnotification/send-email`,
            {
              // emailid: "bamohsin@pagoanalytics.com",
              emailid: "haseelaboina@pagoanalytics.com",
              order_data: [menuData, selectedAddress],
            }
          );
          if (orderResponse.status === 200 && emailResponse.status === 200) {
            dispatch({ type: "DROP" });
            toast.success("Your Order is Successfully Placed and will be delivered soon.");
            if (actions && typeof actions.orderPlaced === "function") {
              actions.orderPlaced();
            }
          }
        },
        prefill: {
          name: selectedAddress.name,
          email: selectedAddress.delivery_email,
          contact: selectedAddress.delivery_phone,
        },
        notes: {
          city: selectedAddress.City,
          address: selectedAddress.Location,
          pincode: selectedAddress.Pincode,
          landmark: selectedAddress.Landmark,
        },
        theme: { color: "#FD7E4B" },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Order placement error:", error);
      toast.error("Error placing your order. Please try again.");
    }
  };

  return (
    <div className="widget-container">
      <h3>Order Summary</h3>
      <div>
        <h4>Products:</h4>
        {menuData.length > 0 ? (
          <ul>
            {menuData.map((item, index) => (
              <li key={index}>
                {item.name} – Qty: {item.qty} – Price: ₹{item.price}
              </li>
            ))}
          </ul>
        ) : (
          <p>No products in cart.</p>
        )}
      </div>
      <div className="summary-row">
        <p>Subtotal:</p>
        <p>₹{subtotal}</p>
      </div>
      <div className="summary-row">
        <p>GST (18%):</p>
        <p>₹{GST.toFixed(2)}</p>
      </div>
      <div className="summary-row">
        <p>Delivery Charges:</p>
        <p>₹{deliveryCharges}</p>
      </div>
      <div className="summary-row total-amount">
        <p>Total Amount:</p>
        <p>₹{finalAmount}</p>
      </div>
      <div className="address-container">
        <h4>Delivery Address</h4>
        {selectedAddress ? (
          <div>
            <p>{selectedAddress.name}</p>
            <p>
              {selectedAddress.Location}, {selectedAddress.City}, {selectedAddress.State} – {selectedAddress.Pincode}
            </p>
            <p>{selectedAddress.delivery_phone}</p>
            {selectedAddress.delivery_email && <p>{selectedAddress.delivery_email}</p>}
          </div>
        ) : (
          <p>No address provided.</p>
        )}
      </div>
      <button onClick={handlePlaceOrder} className="widget-button">
        Place Order
      </button>
    </div>
  );
};

export default ChatOrderSummaryWidget;
