// import React, { useState } from "react";
// import "../../ChatWidgets.css";
// import { toast } from "react-toastify";

// const ConfirmOrderWidget = ({ actions }) => {
//   const [address, setAddress] = useState({
//     name: "",
//     phone: "",
//     location: "",
//     city: "",
//     pincode: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setAddress((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleSubmit = () => {
//     // Simple validation for required fields
//     if (
//       address.name &&
//       address.phone &&
//       address.location &&
//       address.city &&
//       address.pincode
//     ) {
//       actions.submitOrder(address); // Pass the order details for further processing
//     } else {
//       toast.error("Please fill in all required fields.");
//     }
//   };

//   return (
//     <div className="widget-container">
//       <h3>Confirm Order</h3>
//       <input
//         type="text"
//         name="name"
//         placeholder="Name"
//         value={address.name}
//         onChange={handleChange}
//       />
//       <input
//         type="text"
//         name="phone"
//         placeholder="Phone"
//         value={address.phone}
//         onChange={handleChange}
//       />
//       <input
//         type="text"
//         name="location"
//         placeholder="Address"
//         value={address.location}
//         onChange={handleChange}
//       />
//       <input
//         type="text"
//         name="city"
//         placeholder="City"
//         value={address.city}
//         onChange={handleChange}
//       />
//       <input
//         type="text"
//         name="pincode"
//         placeholder="Pincode"
//         value={address.pincode}
//         onChange={handleChange}
//       />
//       <button onClick={handleSubmit} className="widget-button">
//         Submit Order
//       </button>
//     </div>
//   );
// };

// export default ConfirmOrderWidget;


import React, { useState } from "react";
import { toast } from "react-toastify";
import "../../ChatWidgets.css"; // Assuming you share styles with other chat widgets

const ConfirmOrderWidget = ({ actions }) => {
  const [address, setAddress] = useState({
    name: "",
    delivery_phone: "",
    Location: "",
    City: "",
    Pincode: "",
    Landmark: "",
    State: "",
    delivery_email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    // Validate required fields
    if (
      !address.name ||
      !address.delivery_phone ||
      !address.Location ||
      !address.City ||
      !address.Pincode ||
      !address.State ||
      !address.delivery_email
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    // If validation passes, trigger the order summary flow.
    actions.submitOrder(address);
  };

  return (
    <div className="widget-container">
      <h3>Confirm Order</h3>
      <input
        type="text"
        name="name"
        placeholder="Name"
        value={address.name}
        onChange={handleChange}
      />
      <input
        type="text"
        name="delivery_phone"
        placeholder="Phone"
        value={address.delivery_phone}
        onChange={handleChange}
      />
      <input
        type="text"
        name="Location"
        placeholder="Address"
        value={address.Location}
        onChange={handleChange}
      />
      <input
        type="text"
        name="City"
        placeholder="City"
        value={address.City}
        onChange={handleChange}
      />
      <input
        type="text"
        name="Pincode"
        placeholder="Pincode"
        value={address.Pincode}
        onChange={handleChange}
      />
      <input
        type="text"
        name="Landmark"
        placeholder="Landmark (optional)"
        value={address.Landmark}
        onChange={handleChange}
      />
      <input
        type="text"
        name="State"
        placeholder="State"
        value={address.State}
        onChange={handleChange}
      />
      <input
        type="email"
        name="delivery_email"
        placeholder="Email"
        value={address.delivery_email}
        onChange={handleChange}
      />
      <button onClick={handleSubmit} className="widget-button">
        Submit Order
      </button>
    </div>
  );
};

export default ConfirmOrderWidget;
