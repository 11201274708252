import React from "react";
import { GiCookingPot } from "react-icons/gi"; // Icon for "Ready to Cook"
import { FaUtensils } from "react-icons/fa";     // Icon for "Ready to Eat"
import { IoIosArrowForward } from "react-icons/io";
// import "../chatW";
import "../ChatWidgets.css";

const OrderNowWidget = ({ actions }) => {
    const options = [
        { text: "Ready to Cook", icon: <GiCookingPot />, handler: actions.handleReadyToCook },
        { text: "Ready to Eat", icon: <FaUtensils />, handler: actions.handleReadyToEat },
    ];

    const handleOptionClick = (handler) => {
        handler();
    };

    return (
        <div className="widget-container">
            {options.map((option, index) => (
                <button 
                    key={index} 
                    className="widget-button option-button" 
                    onClick={() => handleOptionClick(option.handler)}
                    style={{
                        display: "flex",
                        alignItems: "center", 
                        justifyContent: "space-between", 
                        padding: "12px 20px", 
                        fontSize: "14px", 
                        fontWeight: "600",
                        border: "none", 
                        borderRadius: "8px", 
                        color: "white", 
                        cursor: "pointer", 
                        background: "linear-gradient(135deg, rgb(190, 92, 53), #FD7E4B)", 
                        transition: "transform 0.3s ease-in-out",
                        marginTop: "10px"
                    }}
                >
                    <span style={{ marginRight: "10px" }}>{option.icon}</span>
                    <span>{option.text}</span>
                    <IoIosArrowForward className="arrow-icon" />
                </button>
            ))}
        </div>
    );
};

export default OrderNowWidget;

