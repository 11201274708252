    import React from "react";
    import { toast } from "react-toastify";
    // import "react-toastify/dist/ReactToastify.css";

    // toast.configure();

    const CallSupportWidget = ({ actions }) => {
        const handleCall = () => {
            if (window.innerWidth > 1024) {
                toast.info("Please call 6360463695 from your phone.");
            } else {
                actions.handleCallSupport("6360463695");
            }
        };

        return (
            <div className="widget-container">
                <p>📞 Call our support team: <strong>6360463695</strong></p>
                <button
        onClick={handleCall}
        style={{
            background: "linear-gradient(135deg, #FF914D, #D66A35)",  // Warm orange gradient
            color: "white",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 16px",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "all 0.3s ease-in-out",
        }}
        onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
        onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
    >
        📞 Call Now
    </button>

                {/* <button onClick={handleCall}>
                    Call Now
                </button> */}
            </div>
        );
    };

    export default CallSupportWidget;

//     import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import remoteConfig from "@react-native-firebase/remote-config";

// const CallSupportWidget = ({ actions }) => {
//     const [supportPhoneNumber, setSupportPhoneNumber] = useState("+1 234-567-890");

//     useEffect(() => {
//         const fetchRemoteConfig = async () => {
//             try {
//                 await remoteConfig().setConfigSettings({
//                     minimumFetchIntervalMillis: 1000,
//                 });
//                 await remoteConfig().fetchAndActivate();

//                 const fetchedPhoneNumber = remoteConfig()
//                     .getValue("tjp_support_phone")
//                     .asString();
                
//                 if (fetchedPhoneNumber) {
//                     setSupportPhoneNumber(fetchedPhoneNumber);
//                 }
//             } catch (error) {
//                 console.error("Error fetching remote config:", error);
//             }
//         };

//         fetchRemoteConfig();
//     }, []);

//     const handleCall = () => {
//         if (window.innerWidth > 1024) {
//             toast.info(`Please call ${supportPhoneNumber} from your phone.`);
//         } else {
//             actions.handleCallSupport(supportPhoneNumber);
//         }
//     };

//     return (
//         <div className="widget-container">
//             <p>📞 Call our support team: <strong>{supportPhoneNumber}</strong></p>
//             <button
//                 onClick={handleCall}
//                 style={{
//                     background: "linear-gradient(135deg, #FF914D, #D66A35)",
//                     color: "white",
//                     fontSize: "14px",
//                     fontWeight: "bold",
//                     padding: "10px 16px",
//                     border: "none",
//                     borderRadius: "8px",
//                     cursor: "pointer",
//                     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                     transition: "all 0.3s ease-in-out",
//                 }}
//                 onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
//                 onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
//             >
//                 📞 Call Now
//             </button>
//         </div>
//     );
// };

// export default CallSupportWidget;
