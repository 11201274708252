import React from "react";

const OrderStatusWidget = ({ payload }) => {
    if (!payload || !payload.order) {
        return <p>⚠️ Error: No order details available.</p>;
    }

    const { order } = payload;

    // Ensure order_data exists before accessing
    const orderId = order?.order_data?.[2]?.orderId || "N/A";
    const itemName = order?.order_data?.[0]?.[0]?.name || "Unknown Item";
    const status = order?.status || "Unknown Status";

    const getDeliveryEstimate = (status) => {
        const estimates = {
            "Order-Placed": "📦 Your order is being prepared. Expected delivery: 5-7 days.",
            "Under-Process": "⏳ Your order is being processed. Please wait for further updates.",
            "Ready-For-Pickup": "✅ Your order is ready for pickup. Please collect it at your convenience.",
            "Out-For-Delivery": "🚚 Your order is on the way! Expected to arrive soon."
        };
    
        return estimates[status] || "🔄 Checking your order status...";
    };
    return (
        <div className="widget-container">
            <p><strong>Order ID:</strong> {orderId}</p>
            <p><strong>Item:</strong> {itemName}</p>
            <p><strong>Status:</strong> {status}</p>
            <p>{getDeliveryEstimate(status)}</p>
        </div>
    );
};

export default OrderStatusWidget;
