import React from "react";

const WhatsAppWidget = () => {
    return (
        <div className="widget-container">
            <p>Click below to chat with support on WhatsApp.</p>
            <a href="https://wa.me/6360463695" target="_blank" rel="noopener noreferrer">
                📲 Chat on WhatsApp
            </a>
        </div>
    );
};

export default WhatsAppWidget;
