// import React from "react";
// import { FaTruck, FaBox, FaFireAlt, FaHandshake } from "react-icons/fa"; // Importing React Icons
// import "./ChatWidgets.css";
//  import { IoIosArrowForward } from "react-icons/io";
// const OptionsWidget = ({ setState, createChatBotMessage, actions }) => {
//     const options = [
//         { text: "Track Order", icon: <FaTruck />, handler: actions.handleTrackOrder },
//         { text: "Shipping Policy", icon: <FaBox />, handler: actions.handleShippingPolicy },
//         { text: "Stay Updated with Deals", icon: <FaFireAlt />, handler: actions.handleStayUpdated },
//         { text: "Partner / Collaboration", icon: <FaHandshake />, handler: actions.handlePartnerCollab },
//     ];

//     const handleOptionClick = (handler) => {
//         handler();
//     };

//     return (
//         <div className="widget-container">
//             {options.map((option, index) => (
//                 <button 
//                     className="widget-button option-button" 
//                     key={index} 
//                     onClick={() => handleOptionClick(option.handler)}
//                     style={{
//                         display: "flex",
//                         alignItems: "center", 
//                         justifyContent: "space-between", 
//                         padding: "12px 20px", 
//                         fontSize: "14px", 
//                         fontWeight: "600",
//                         border: "none", 
//                         borderRadius: "8px", 
//                         color: "white", 
//                         cursor: "pointer", 
//                         // background: "linear-gradient(135deg, #FD7E4B,rgb(190, 92, 53))",     
//                         background: "linear-gradient(135deg,rgb(190, 92, 53), #FD7E4B)", 
//                         // background: "linear-gradient(135deg, #FD7E4B, #f5dcb6)", 
//                         transition: "transform 0.3s ease-in-out", 
//                         marginTop:'0px'
//                     }}
//                 >
//                     <span style={{ marginRight: "10px" }}>{option.icon}</span> {/* Icon added here */}
//                     <span>{option.text}</span>
//                     <IoIosArrowForward className="arrow-icon" /> 
//                 </button>
//             ))}
//         </div>
//     );
// };

// export default OptionsWidget;

import React from "react";
import { FaTruck, FaBox, FaFireAlt, FaHandshake, FaShoppingCart } from "react-icons/fa"; // Importing React Icons
import "./ChatWidgets.css";
import { IoIosArrowForward } from "react-icons/io";

const OptionsWidget = ({ setState, createChatBotMessage, actions }) => {
    const options = [
        { text: "Order Now", icon: <FaShoppingCart />, handler: actions.handleOrderNow },
        { text: "Track Order", icon: <FaTruck />, handler: actions.handleTrackOrder },
        { text: "Shipping Policy", icon: <FaBox />, handler: actions.handleShippingPolicy },
        { text: "Stay Updated with Deals", icon: <FaFireAlt />, handler: actions.handleStayUpdated },
        { text: "Partner / Collaboration", icon: <FaHandshake />, handler: actions.handlePartnerCollab },
    ];

    const handleOptionClick = (handler) => {
        handler();
    };

    return (
        <div className="widget-container">
            {options.map((option, index) => (
                <button 
                    className="widget-button option-button" 
                    key={index} 
                    onClick={() => handleOptionClick(option.handler)}
                    style={{
                        display: "flex",
                        alignItems: "center", 
                        justifyContent: "space-between", 
                        padding: "12px 20px", 
                        fontSize: "14px", 
                        fontWeight: "600",
                        border: "none", 
                        borderRadius: "8px", 
                        color: "white", 
                        cursor: "pointer", 
                        background: "linear-gradient(135deg,rgb(190, 92, 53), #FD7E4B)", 
                        transition: "transform 0.3s ease-in-out", 
                        marginTop:'0px'
                    }}
                >
                    <span style={{ marginRight: "10px" }}>{option.icon}</span>
                    <span>{option.text}</span>
                    <IoIosArrowForward className="arrow-icon" /> 
                </button>
            ))}
        </div>
    );
};

export default OptionsWidget;
